import React from 'react';
import ListContainer from "../containers/ListConatiner";

const ListPage = () => {
    return (
        <>
            <ListContainer />
        </>
    );
};

export default ListPage;