import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ChevronLeft, Plus} from "react-feather";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {useGetOneQuery, usePostQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import patientImg from "../../../assets/images/patient.png";
import {find, get, head, isEqual, isNil} from "lodash";
import phoneIcon from "../../../assets/icons/phone.svg";
import emailIcon from "../../../assets/icons/mail.svg";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import GridView from "../../../containers/grid-view";
import Badge from "../../../components/badge";
import dayjs from "dayjs";
import Modal from "../../../components/modal";
import clsx from "clsx";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import {FIELDS} from "../../../constants";

const PatientEpisodeOfCareContainer = ({id}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState(null);
    const [_id, _setId] = useState(null);
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.patients, key: [KEYS.patients, id]})
    const {
        mutate: createEpisodeOfCareRequest, isLoading: isLoadingPost
    } = usePostQuery({listKeyId: KEYS.episodeOfCares})
    const {
        mutate: dischargeEpisodeOfCareRequest, isLoading: isLoadingPut
    } = usePutQuery({listKeyId: KEYS.episodeOfCares})
    const createEpisodeOfCare = ({data: attrs}) => {
        createEpisodeOfCareRequest({
            url: URLS.episodeOfCares,
            attributes: {
                ...attrs,
                patient: {id: id}
            }
        }, {
            onSuccess: () => {
                setOpen(false);
                setSearchParams('openModal=0')
            },
            onError: () => {

            }
        })
    }
    const dischargeEpisodeOfCare = ({data: attrs}) => {
        dischargeEpisodeOfCareRequest({url: `${URLS.episodeOfCares}/${_id}/discharge`, attributes: {...attrs}}, {
            onSuccess: () => {
                _setId(null);
            }
        })
    }
    useEffect(() => {
        if (parseInt(searchParams.get('openModal'))) {
            setOpen(true);
        }
    }, [searchParams.get('openModal')]);
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <button onClick={() => navigate(`/patient/view/${id}`)} className={'text-primary  font-bold inline-flex items-center'}
                    ><ChevronLeft className={'mr-1'}/>{t("Назад к пациент")}</button>
                </div>
                <div className="col-span-12">
                    <Content sm>
                        <Title className={'mb-6'}>{t("Д учёт")}</Title>
                        <div className="grid grid-cols-12 gap-x-10">
                            <div className="col-span-6">
                                <div
                                    className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                    <div className={'flex items-center'}>
                                        <img src={patientImg} alt=""/>
                                        <Link to={`/patient/view/${id}`} className={'text-lg ml-5 font-bold'}>{get(data, 'data.payload.patient.last_name')} {get(data, 'data.payload.patient.first_name')} {get(data, 'data.payload.patient.middle_name')}</Link>
                                    </div>
                                    <div className="grid grid-cols-12 mt-5">
                                        <div className="col-span-7">
                                            <ul>
                                                <li className={'grid-cols-12 grid'}>
                                                    <strong className={'col-span-6'}>Пол:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{get(data, 'data.payload.patient.gender.display')}</span>
                                                </li>
                                                <li className={'grid-cols-12 grid mt-4'}>
                                                    <strong className={'col-span-6'}>Возраст:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{dayjs().format("YYYY") - dayjs(get(data, 'data.payload.patient.birth_date')).format("YYYY")}</span>
                                                </li>
                                                <li className={'grid-cols-12 grid mt-4'}>
                                                    <strong className={'col-span-6'}>Дата рождения:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{get(data, 'data.payload.patient.birth_date')}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-span-5">
                                            <ul>
                                                {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value') &&
                                                    <li className={'flex items-center '}>
                                                        <img className={'flex-none'} src={phoneIcon} alt=""/>
                                                        <span
                                                            className={'ml-4'}>+{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value')}</span>
                                                    </li>}
                                                {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value') &&
                                                    <li className={'flex items-center mt-4'}>
                                                        <img className={'flex-none'} src={emailIcon} alt=""/>
                                                        <span
                                                            className={'ml-4'}>{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value')}</span>
                                                    </li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-span-12 flex items-center justify-end">
                            <AllowedAccess permissions={[config.PERMISSIONS.episode_of_care_create]}>
                                <button
                                    onClick={() => setOpen(true)}
                                    className={'inline-flex py-2.5 pl-2.5 pr-5 rounded-lg bg-primary items-center text-white font-semibold text-center '}>
                                    <Plus className={'mr-1.5'}/>
                                    {t('Д учёт')}
                                </button>
                            </AllowedAccess>
                        </div>
                        <div className="col-span-12 mt-3">
                            <div
                                className={"rounded-xl shadow-xl  border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                <GridView
                                    bordered
                                    dataKey={'data.payload.data'}
                                    metaDataKey={'data.payload.meta'}
                                    params={{
                                        patient_id: id,
                                        sort:'-updated_at'
                                    }}
                                    listKey={KEYS.episodeOfCares}
                                    url={URLS.episodeOfCares}
                                    columns={[
                                        {
                                            title: t('№'),
                                            key: 'id',
                                            render: ({number}) => number,
                                        },
                                        {
                                            title: t('Статус Д учета'),
                                            key: 'status.display',
                                            render: ({value}) => <Badge>{value}</Badge>
                                        },
                                        {
                                            title: t('Заключительный диагноз'),
                                            key: 'main_diagnosis.code.code',
                                            render: ({row}) => `${get(row, 'main_diagnosis.code.code')} ${get(row, 'main_diagnosis.code.display')}`
                                        },
                                        {
                                            title: t('Характер заболевания'),
                                            key: 'main_diagnosis.severity.display',
                                        },
                                        {
                                            title: t('Дата внесения данных'),
                                            key: 'main_diagnosis.onset_date',
                                            render: ({value}) => dayjs(value).format('DD.MM.YYYY HH:mm')
                                        },
                                        {
                                            title: t('Дата снятия с учета'),
                                            key: 'discharge.created_at',
                                            render: ({value}) => value ? dayjs(value).format('DD.MM.YYYY HH:mm') : null
                                        },
                                        {
                                            title: t('Reason'),
                                            key: 'discharge.reason_code.display',
                                            render: ({value, row}) => <span>
                                                {value} {get(head(get(row, 'discharge.diagnoses', [])), 'text') && `(${get(head(get(row, 'discharge.diagnoses', [])), 'text')})`}
                                            </span>,
                                            checkIsVisible:true
                                        },
                                        {
                                            title: t('Примечание'),
                                            key: 'note',
                                            checkIsVisible:true
                                        },
                                        {
                                            title: t('Действие'),
                                            key: 'discharge',
                                            render: ({value, row}) => <AllowedAccess
                                                permissions={[config.PERMISSIONS.episode_of_care_delete]}>
                                                <button disabled={value}
                                                        onClick={() => _setId(get(row, 'id'))}
                                                        className={clsx('bg-[#B45858] inline-flex py-2.5 px-5  rounded-lg  items-center text-white font-semibold text-center', {'!bg-white !text-black border': value})}>{!value ? 'СНЯТЬ' : 'СНЯТО'}</button>
                                            </AllowedAccess>
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
            <Modal open={open} onClose={() => setOpen(false)} classNames={'!w-[1080px] !pb-0'}
                   title={t('Диспансерный учет')}>
                {(isLoadingPost) && <ContentLoader/>}
                <div
                    className={"pb-6"}>
                    <Form classNames={'grid grid-cols-12 gap-x-6'} formRequest={createEpisodeOfCare}
                          footer={<div className={'col-span-12 '}>
                              <div className="flex justify-end">
                                  <button onClick={() => setOpen(false)} type={'button'}
                                          className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                      {t('Отмена')}
                                  </button>
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>
                          </div>}>

                        <Field
                            type={FIELDS.treeSelect}
                            url={URLS.icd10}
                            keyId={KEYS.icd10}
                            classNames={'col-span-6'}
                            name={'main_diagnosis.code.id'}
                            params={{
                                required: true,
                            }}
                            label={t('Заключительный диагноз')}
                        />

                        <Field
                            type={FIELDS.asyncSelect}
                            url={URLS.conditionSeverity}
                            keyId={KEYS.conditionSeverity}
                            classNames={'col-span-6'}
                            name={'main_diagnosis.severity'}
                            params={{
                                required: true,
                            }}
                            label={t('Характер заболевания')}
                        />
                        <Field
                            type={FIELDS.asyncSelect}
                            url={URLS.episodeOfCareStatus}
                            keyId={KEYS.episodeOfCareStatus}
                            classNames={'col-span-6'}
                            name={'status'}
                            params={{
                                required: true,
                            }}
                            label={t('Статус Д учета')}
                        />
                        <Field
                            dateFormat={"yyyy-MM-dd"}
                            type={FIELDS.datepicker}
                            classNames={'col-span-3'}
                            name={'main_diagnosis.onset_date'}
                            params={{
                                required: true,
                            }}
                            property={{
                                maxDate: dayjs().toDate()
                            }}
                            label={t('Дата начала заболевания')}
                        />

                        <Field
                            disabled
                            dateFormat={"yyyy-MM-dd"}
                            type={FIELDS.datepicker}
                            classNames={'col-span-3'}
                            name={'period_start'}
                            params={{
                                required: true,
                            }}
                            label={t('Дата внесения данных')}
                        />
                        <Field
                            type={FIELDS.textarea}
                            classNames={'col-span-12'}
                            name={'note'}
                            label={t('Примечание')}
                        />
                    </Form>
                </div>
            </Modal>
            <Modal open={!isNil(_id)} onClose={() => _setId(null)} classNames={'!w-[1080px] !pb-0'}
                   title={t('Cнять c диспансерный учета')}>
                {(isLoadingPut) && <ContentLoader/>}
                <div
                    className={"pb-6"}>
                    <Form classNames={'grid grid-cols-12 gap-x-6'} formRequest={dischargeEpisodeOfCare}
                          footer={<div className={'col-span-12 '}>
                              <div className="flex justify-end">
                                  <button onClick={() => _setId(null)} type={'button'}
                                          className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                      {t('Отмена')}
                                  </button>
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>
                          </div>}>
                        <Field
                            type={FIELDS.asyncSelect}
                            url={URLS.episodeOfCaresDischargeReason}
                            keyId={KEYS.episodeOfCaresDischargeReason}
                            classNames={'col-span-12'}
                            name={'reason_code'}
                            params={{
                                required: true,
                            }}
                            property={{
                                onChange: (val) => setCode(get(val, 'code'))
                            }}
                            label={t('Snyat qilish sabab')}
                        />
                        {isEqual(code, 'death') && <> <Field
                            type={FIELDS.treeSelect}
                            url={URLS.icd10}
                            keyId={KEYS.icd10}
                            classNames={'col-span-12'}
                            name={'diagnoses[0].code.id'}
                            params={{
                                required: true,
                            }}
                            label={t('Заключительный диагноз')}
                        />
                            <Field
                                type={FIELDS.input}
                                classNames={'col-span-12'}
                                name={'diagnoses[0].text'}
                                label={t('Death reason')}
                            />
                        </>}
                        <Field
                            type={FIELDS.textarea}
                            classNames={'col-span-12'}
                            name={'note'}
                            label={t('Примечание')}
                        />
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default PatientEpisodeOfCareContainer;
