import React from 'react';
import {get} from "lodash";
import dayjs from "dayjs";
import Badge from "../badge";
import {useTranslation} from "react-i18next";

const Index = ({row={}}) => {
    const {t} = useTranslation()
    return (
        <>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Наименование")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'medication.vproduct.display')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Разовая доза")}:</span>
                        <strong
                            className={'w-2/3'}>{` ${get(row, 'instructions[0].per_dose_qty')} ${get(row, 'instructions[0].dosage_unit.display')}`}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Частота приёма")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'instructions[0].timing.frequency.display')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Количество дней приема")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'instructions[0].timing.days_count')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Обшее количество ЛС")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'instructions[0].quantity')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Способ введения")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'instructions[0].route.display')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Примечание")}:</span>
                        <strong className={'w-2/3'}>{get(row, 'instructions[0].patient_instruction')}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Создано в")}:</span>
                        <strong
                            className={'w-2/3'}>{dayjs(get(row, 'created_at')).format("DD.MM.YYYY HH:mm")}</strong>
                    </div>
                    <div className={'flex py-5 border-b items-center'}>
                        <span className={'w-1/3'}>{t("Status")}:</span>
                        <strong className={'w-2/3'}> <Badge
                            status={get(row, 'status.code')}>{get(row, 'status.display')}</Badge></strong>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
