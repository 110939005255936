import React from 'react';
import {useParams} from "react-router-dom"
import Patient066ViewContainer from "../containers/Patient066ViewContainer";

const Patient066ViewPage = () => {
    const {id} = useParams()
    return (
        <>
            <Patient066ViewContainer id={id}/>
        </>
    );
};

export default Patient066ViewPage;
