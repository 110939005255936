import React from 'react';
import MapContainer from "../containers/MapContainer";

const MapPage = () => {
    return (
        <>
         <MapContainer/>
        </>
    );
};

export default MapPage;
