import React from 'react';
import Content from "../../../components/content";
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import Map from "../../../components/map";
import SelectComponent from "../../../components/select";
const MapContainer = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="flex justify-between items-center">
                <Title className={'mb-5'}>{t("Картография ")}</Title>
            </div>
            <Content classNames={'bg-white rounded-2xl !min-h-50vh pt-10'}>
                <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-3">
                        <SelectComponent
                            className={'mb-3'}
                            fullWidth
                            options={[]}
                            placeholder={t('Xудудни танлаш')}/>
                        <SelectComponent
                            fullWidth
                            className={'mb-3'}
                            options={[]}
                            placeholder={t('Ташкилот турини танлаш')}/>
                        <SelectComponent
                            fullWidth
                            className={'mb-3'}
                            options={[]}
                            placeholder={t('Ташкилот босқичини танлаш')}/>
                        <SelectComponent
                            fullWidth
                            className={'mb-3'}
                            options={[]}
                            placeholder={t('Визуализация сонини танлаш')}/>
                        <SelectComponent
                            fullWidth
                            className={'mb-3'}
                            options={[]}
                            placeholder={t('Визуализация турини танлаш')}/>
                        <SelectComponent
                            fullWidth
                            className={'mb-3'}
                            options={[]}
                            placeholder={t('Аҳоли категорияси')}/>
                    </div>
                    <div className="col-span-9">
                        <Map />
                    </div>
                </div>
            </Content>
        </>
    );
};

export default MapContainer;
