import React,{useMemo} from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {Search} from "react-feather";
import {debounce} from "lodash";

const Input = ({

                   timeout = 1000, label = '', value = '', setValue = () => {
    },
                   placeholder = 'Search',
                   classNames = '',
               }) => {
    const {t} = useTranslation()
    const handleChange = useMemo((e) => debounce((e) => {
        setValue(e.target.value)
    }, timeout), [])

    return (
        <div className={clsx('flex items-center')}>
            {label && <label className={clsx('mr-2 text-secondary-300 font-bold flex items-center')}
                             htmlFor={label}>{label}</label>}
            <div className={clsx('relative border py-2.5 px-4 rounded-lg min-w-[300px] h-10', classNames)}>
                <Search color={'#A7A7A7'} className={'absolute left-2 z-10'} size={18}/>
                <input placeholder={t(placeholder)}
                       className={'left-9 top-0 bg-transparent absolute  outline-none h-full right-0 pr-4'} type="search"
                       defaultValue={value} onChange={handleChange}/>
            </div>
        </div>
    );
};

export default Input;