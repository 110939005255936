import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import GridView from "../../../containers/grid-view";
import {URLS} from "../../../constants/urls";
import {KEYS} from "../../../constants/keys";
import Content from "../../../components/content";
import YearMonthPicker from "../../../components/year-month-picker";
import {NumericFormat} from "react-number-format";

const BudgetByOrganizationContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const {t} = useTranslation()
    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 mb-5">
                <Title>{t("Budget by organization")}</Title>
            </div>
            <div className={'col-span-12'}>
                <Content classNames={'!bg-white'}>
                    <div className="grid grid-cols-12 gap-x-8">
                        <div className="col-span-12">
                            <div className={'mb-3'}>
                                <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                            </div>
                            {year && month && <GridView
                                url={URLS.getBudgetByOrganization}
                                listKey={KEYS.getBudgetByOrganization}
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                params={{
                                    fiscal_year: parseInt(year),
                                    month: parseInt(month)
                                }}
                                columns={[
                                    {
                                        title: t('№'),
                                        key: 'id',
                                        render: ({number}) => number,
                                    },
                                    {
                                        title: t('Город'),
                                        key: 'area.display'
                                    },
                                    {
                                        title: t('Бюджет на месяц'),
                                        key: 'allocated_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    },
                                    {
                                        title: t('С прошлого месяца'),
                                        key: 'spent_amount',
                                        render: ({value}) => 0
                                    },
                                    {
                                        title: t('Общая сумма'),
                                        key: 'allocated_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    }
                                ]}
                            />}
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default BudgetByOrganizationContainer;