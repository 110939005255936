import React from 'react';
import StatisticsContainer from "../containers/StatisticsContainer";

const StatisticsPage = () => {
    return (
        <>
         <StatisticsContainer />
        </>
    );
};

export default StatisticsPage;