import React from 'react';
import {find, get,  isEqual} from "lodash";
import {Document, Page, Text, View, StyleSheet, Font} from "@react-pdf/renderer";
import times from "../../assets/fonts/times/times.ttf"
import timesBold from "../../assets/fonts/times/Times New Roman Bold.ttf"
import dayjs from "dayjs";

Font.register({
    family: "Times-R",
    src: times
});
Font.register({
    family: "Times-B",
    src: timesBold
});
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        flex: 1,
        flexDirection: 'column',
        padding: '20 20 15 30',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    column: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    section: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: '5px'
    },
    text: {
        xxs: {fontSize: 6},
        xs: {fontSize: 8},
        sm: {fontSize: 10},
        base: {fontSize: 12},
        lg: {fontSize: 14},
        xl: {fontSize: 16},
        xxl: {fontSize: 18},
    },
    font: {
        times: {
            fontFamily: "Times-R",
        },
        timesB: {
            fontFamily: "Times-B",
        }
    },
    box: {
        border: '1px solid #000',
        padding: '1.5px 3px',
        marginRight: 5
    },
    checkbox: {
        border: '1px solid #000',
        width: 14,
        height: 14,
        marginRight: 3,
        checked: {
            backgroundColor: '#000'
        }
    },
    flex: {
        between: {
            justifyContent: 'space-between'
        },
        center: {
            justifyContent: 'center'
        },
        alignBottom: {
            alignItems: 'center'
        }
    },
    borderBottom: {
        borderBottom: '1px solid #000'
    },
    width: {
        25: {
            width: '25%'
        },
        40: {
            width: '40%'
        },
        50: {
            width: '50%'
        },
        60: {
            width: '60%'
        }
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        borderCollapse:'collapsed'
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        width: "20%", // Adjust width according to your needs
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
});

const Index = ({data}) => {
    return (
        <>
            <Document>
                <Page size="A4" style={[styles.page, styles.font.times, styles.text.base]}>
                    <View style={[styles.section, styles.flex.between, {marginBottom: '10px'}]}>
                        <View style={[styles.borderBottom, {
                            paddingBottom: '5px',
                            marginRight: '25x',
                            width: '45%'
                        }]}>
                            <View style={styles.row}>
                                <View style={[styles.box]}><Text style={[styles.text.xs]}>{get(data, 'organization.display')}</Text></View>
                                <View>
                                    <Text style={styles.text}><Text style={[styles.text.xs]}>0.1.</Text>Муассаса коди
                                        ва <Text
                                            style={[styles.text.xs]}>0.1а</Text> номи:</Text>
                                </View>
                            </View>
                            <View style={[{marginTop: '5px'}]}>
                                <Text>{get(data, 'performer.name')}</Text>
                            </View>
                        </View>
                        <View style={{width: '55%'}}>
                            <Text>Ўзбекистон Республикаси Соғлиқни сақлаш вазирининг
                                15-06-2021 даги 132-сонли буйруғи билан
                                тасдиқланган <View><Text style={[styles.font.timesB]}>066-рақамли
                                </Text></View> тибиий ҳужжат
                                шакли.</Text>
                        </View>
                    </View>
                    <View style={[styles.section, styles.flex.center]}>
                        <Text style={[styles.font.timesB]}>ШИФОХОНАДАН ЧИҚАРИЛГАНЛАРНИНГ СТАТИСТИК КАРТАСИ</Text>
                    </View>
                    <View style={[styles.section, styles.flex.center, {
                        paddingBottom: '8px',
                        borderBottom: '2px solid #000',
                        marginBottom: '10px'
                    }]}>
                        <Text>0.2.Беморнинг рўйхатдан ўтиш рақами:</Text> <View
                        style={[styles.box, {marginLeft: '5px'}]}><Text>{get(data, 'id')}</Text></View>
                    </View>
                    <View style={[styles.section, {marginBottom: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>1. Шахсий маълумот:</Text>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.1.</Text><Text>ЖШШИР:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.pin', '-')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.2.</Text><Text>Фамилияси:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.last_name', '-')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.3.</Text><Text>Исми:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.first_name', '-')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.4.</Text><Text>Отасининг исми:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.middle_name', '-')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.row, {width: '50%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '60%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.5.</Text><Text>Туғилган
                                санаси:</Text>
                            </View>
                            <View>
                                <Text>{dayjs(get(data, 'patient.birth_date')).format("DD-MM-YYYY")}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {width: '50%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.6.</Text><Text>Жинси:</Text>
                            </View>
                            <View style={[styles.row]}>
                                <View style={[styles.row, {marginRight: '10px'}]}>
                                    <View
                                        style={[styles.checkbox, get(data, 'patient.gender.code') == 'male' ? styles.checkbox.checked : '']}></View>
                                    <Text>Эрк.</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <View
                                        style={[styles.checkbox, get(data, 'patient.gender.code') == 'female' ? styles.checkbox.checked : '']}></View>
                                    <Text>Аёл</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.7.</Text><Text>Фуқаролиги:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.citizenship.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.8.</Text><Text>Паспорт № ва
                            серияси:</Text>
                        </View>
                        <View>
                            <Text>{get(find(get(data, 'patient.identifiers', []), (_item) => isEqual(get(_item, 'type.code'), "PPN")), 'value')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.9.</Text><Text>Имтиёзли тоифадаги
                            шахслар:</Text>
                        </View>
                        <View style={[styles.row, {width: '70%', flexWrap: 'wrap'}]}>
                            {get(data, 'patient.benefit', []).map(_item => <View
                                style={[styles.column, {width: '100%'}]}>
                                <View style={[styles.row, {marginRight: '5px'}]}>
                                    <View
                                        style={[styles.checkbox, styles.checkbox.checked, {
                                            width: 12,
                                            height: 12
                                        }]}></View>
                                    <Text style={styles.text.xs}>{get(_item, 'display')}</Text>
                                </View>
                            </View>)}

                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginTop: '5px',
                        marginBottom: '5px',
                        borderBottom: '1px dashed #000'
                    }]}></View>
                    <View style={[styles.section, {marginBottom: '5px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>Яшаб келаётган манзили:</Text>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.10.</Text><Text>ҚР/Вилоят/Тошкент
                            ш.</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.addresses[0].state.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.11.</Text><Text>Туман/шаҳар.</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.addresses[0].city.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.12.</Text><Text>Маҳалла.</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.addresses[0].district.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.13.</Text><Text>Кўча.</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'patient.addresses[0].line')}</Text>
                        </View>
                    </View>
                    <View style={[styles.row]}>
                        <View style={[styles.section, {marginBottom: 0, width: '30%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.14.</Text><Text>Уй.</Text>
                            </View>
                            <View style={[styles.box]}>
                                <Text>____</Text>
                            </View>
                        </View>
                        <View style={[styles.section, {marginBottom: 0, width: '30%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '50%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.15.</Text><Text>Хонадон.</Text>
                            </View>
                            <View style={[styles.box]}>
                                <Text>____</Text>
                            </View>
                        </View>
                        <View style={[styles.section, {marginBottom: 0, width: '40%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '60%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.16.</Text><Text>Почта
                                индекси.</Text>
                            </View>
                            {get(data, 'patient.addresses[0].postal_code') && <View style={[styles.box]}>
                                <Text>{get(data, 'patient.addresses[0].postal_code')}</Text>
                            </View>}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={[styles.section, {marginBottom: 0, marginTop: '3px'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.17.</Text><Text>Телефон.</Text>
                            </View>
                            <View style={[styles.box]}>
                                <Text>+(998){get(data, 'patient.telecoms[0].value')}</Text>
                            </View>
                        </View>
                        <View style={[styles.section, {marginBottom: 0, marginTop: '3px'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.18.</Text><Text>Email.</Text>
                            </View>
                            <Text>______________</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginTop: '5px',
                        borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        {get(data, 'value.subject.generalPractitioner.code') && <View style={styles.box}>
                            <Text>{get(data, 'value.subject.generalPractitioner.code')}</Text>
                        </View>}
                        <View style={[styles.section, styles.flex.alignBottom]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.19.</Text><Text>Бемор бириктирилган
                            БТСЁ коди ва <Text style={[styles.text.xs, {marginRight: '3px'}]}>1.19a.</Text>номи</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'value.subject.generalPractitioner.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>2. Шифохонага ётқизиш ҳақида маълумот:</Text>
                    </View>
                    <View style={[styles.row, {marginBottom: '5px'}]}>
                        <View style={[styles.section, {marginBottom: 0, width: '40%'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '50%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.1.</Text><Text>Тезкор
                                ёрдам.</Text>
                            </View>
                            <View style={[styles.row]}>
                                <View style={[styles.row, {marginRight: '5px'}]}>
                                    <View
                                        style={[styles.checkbox, get(data, 'admission.emergency') ? styles.checkbox.checked : '']}></View>
                                    <Text>Ҳа</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <View
                                        style={[styles.checkbox, !get(data, 'admission.emergency') ? styles.checkbox.checked : '']}></View>
                                    <Text>Йўқ</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.2.</Text><Text>Бемор
                            ётқизилиши.</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '15px', width: '30%'}]}>
                                <View
                                    style={[styles.checkbox, get(data, 'admission.priority.code') == 'plan' ? styles.checkbox.checked : '']}></View>
                                <Text>Режали</Text>
                            </View>
                            <View style={[styles.row, {width: '70%'}]}>
                                <View
                                    style={[styles.checkbox,get(data, 'admission.priority.code') != 'plan' ? styles.checkbox.checked : '']}></View>
                                <View style={{width:'70%'}}>
                                    <Text>Касаллик ёки жароҳат
                                        олгандан кейин неча соат
                                        кейин</Text>
                                    <View style={[styles.row]}>
                                        <View style={[styles.row, {marginRight: '5px', width: '15%'}]}>
                                            <Text style={styles.text.xs}>2.2a.</Text>
                                        </View>
                                        <View style={[styles.column, {width: '85%'}]}>
                                            <View style={[styles.row]}>
                                                <View
                                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'admission.priority.code') == 'aft6' ? styles.checkbox.checked : '']}></View>
                                                <View>
                                                    <Text style={styles.text.xs}>дастлабки 6 соат</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.row]}>
                                                <View
                                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'admission.priority.code') == 'within-7-24' ? styles.checkbox.checked : '']}></View>
                                                <View>
                                                    <Text style={styles.text.xs}>7-24 соат ичида.</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.row]}>
                                                <View
                                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'admission.priority.code') == 'aft24' ? styles.checkbox.checked : '']}></View>
                                                <View>
                                                    <Text style={styles.text.xs}>24соатдан кейин.</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0, marginTop: '30px'}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.3.</Text><Text>Тез тиббий ёрдам
                            ичида.
                            мошинасида келдими.</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '5px'}]}>
                                <View
                                    style={[styles.checkbox, get(data, 'admission.by_ambulance') ? styles.checkbox.checked : '']}></View>
                                <Text>Ҳа</Text>
                            </View>
                            <View style={[styles.row]}>
                                <View
                                    style={[styles.checkbox, !get(data, 'admission.by_ambulance') ? styles.checkbox.checked : '']}></View>
                                <Text>Йўқ</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '35%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.4.</Text><Text>Йўлланмасми борми
                            .</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, get(data, 'service_request') ? styles.checkbox.checked : '']}></View>
                                <Text>Ҳа</Text>
                            </View>
                            <View style={[styles.row]}>
                                <View
                                    style={[styles.checkbox, !get(data, 'service_request') ? styles.checkbox.checked : '']}></View>
                                <Text>Йўқ</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginTop: '5px'}]}>
                        <View style={[styles.section, styles.flex.alignBottom]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.5.</Text><Text>Йўллаган муассаса коди
                            ва <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.5a.</Text>номи </Text>
                        </View>
                        <View style={{width: '55%', marginLeft: '15px'}}>
                            <Text> {get(data, 'service_request.referrer_organization.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginTop: '5px',
                    }]}>
                        {get(data, 'service_request.reason_condition.code') && <View style={[{width: '5%'}]}>
                            <Text>{get(data, 'service_request.reason_condition.code.code')}</Text>
                        </View>}
                        <View style={[styles.section, styles.flex.alignBottom, {
                            marginRight: '15px',
                            marginLeft: '15px',
                            width: '45%'
                        }]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.6.</Text><Text>Йўллаган муассаса
                            ташхиси <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.6a.</Text></Text>
                        </View>
                        <View style={[{width: '50%'}]}>
                            <Text>{get(data, 'service_request.reason_condition.code.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginBottom: 0, borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '55%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>2.7.</Text><Text>Айнан шу касаллик
                            билан ўтган 30 кунда
                            шифохонага ётқизилган:</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, !get(data, 'admission.repeated_within_30d') ? styles.checkbox.checked : '']}></View>
                                <Text>Биринчи марта</Text>
                            </View>
                            <View style={[styles.row]}>
                                <View
                                    style={[styles.checkbox, get(data, 'admission.repeated_within_30d') ? styles.checkbox.checked : '']}></View>
                                <Text>Қайта</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginBottom: '10px', marginTop: '10px',
                    }]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>3. Шифохонада даволаниш:</Text>
                    </View>
                    <View style={[styles.section, {
                        marginTop: '5px',
                    }]}>
                        <View style={styles.box}>
                            <Text>___._</Text>
                        </View>
                        <View style={[styles.section, styles.flex.alignBottom]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.1.</Text><Text>Қабул бўлими
                            ташхиси <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.1a.</Text></Text>
                        </View>
                        <View>
                            <Text>{get(data,'admission.admission_diagnosis.code.display')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.2.</Text><Text>Ётқизилган
                            санаси ва вақти:</Text>
                        </View>
                        <View>
                            <Text>{dayjs(get(data, 'admission.admission_date')).format('DD/MM/YYYY')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.3.</Text><Text>Ўрин жой тури</Text>
                        </View>
                        <View>
                            <Text>{get(data,'admission.bed_type.code')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.4.</Text><Text>Чиқарилган
                            санаси ва вақти:</Text>
                        </View>
                        <View>
                            <Text>{dayjs(get(data, 'period_end')??get(data,'discharge.discharge_date')).format('DD/MM/YYYY')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.5.</Text><Text>Ўрин кунлар</Text>
                        </View>
                        <View>
                            <Text>{dayjs(get(data, 'period_end') ?? get(data,'discharge.discharge_date')).diff(get(data, 'period_start'), 'day')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginTop: '5px',
                    }]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '40%', marginRight: '25px'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.6.</Text><Text>Чиқарилган бўлим коди
                            ва <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.6a.</Text>номи</Text>
                        </View>
                        <View style={[{width: '60%'}]}>
                            <Text>{get(data, 'performer.name')}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginBottom: 0, borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '35%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>3.7.</Text><Text>Касаланниш
                            якунида:</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, {
                                        width: 12,
                                        height: 12
                                    }, get(data, 'discharge.discharge_status.code') == "home" ? styles.checkbox.checked : '']}></View>
                                <Text style={styles.text.xs}>Чиқарилди</Text>
                            </View>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'discharge.discharge_status.code') == "exp" ? styles.checkbox.checked : '']}></View>
                                <Text style={styles.text.xs}>Вафот этди</Text>
                            </View>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'discharge.discharge_status.code') == "aadvice" ? styles.checkbox.checked : '']}></View>
                                <Text style={styles.text.xs}>Ўзи чиқиб кетди</Text>
                            </View>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox, {width: 12, height: 12},get(data, 'discharge.discharge_status.code') == "other-hcf" ? styles.checkbox.checked : '']}></View>
                                <Text style={styles.text.xs}>Бошқа шифоханага ўтказилди</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {
                        marginBottom: '10px', marginTop: '10px',
                    }]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>4. Беморнинг якуний ташҳиси:</Text>
                    </View>
                    <View style={[styles.section, styles.column.flexDirection, {
                        marginBottom: 0, borderBottom: '2px solid #000',
                        paddingBottom: '10px', flexDirection: 'column'
                    }]}>
                        {get(data, 'admission.admission_diagnosis') &&
                            <View style={[styles.section, styles.flex.alignBottom]}><View
                                style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>4.1.</Text><Text>Асосий
                                ташхис:</Text>
                            </View>
                                <View style={[styles.section, {
                                    marginTop: '5px',
                                }]}>
                                    <View style={styles.box}>
                                        <Text>{get(data, 'admission.admission_diagnosis.code.code')}</Text>
                                    </View>

                                    <View>
                                        <Text>{get(data, 'admission.admission_diagnosis.code.display')}</Text>
                                    </View>
                                </View>
                            </View>}
                        {get(data, 'diagnoses', []).map((diagnose,index) => <>
                            <View style={[styles.section, styles.flex.alignBottom]}><View
                                style={[styles.section, styles.flex.alignBottom, {width: '30%', marginRight: '30px'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>4.{index+2}.</Text><Text>Асорати/Йўлдош касаллик:</Text>
                            </View>
                                <View style={[styles.section, {
                                    marginTop: '5px',
                                }]}>
                                    <View style={styles.box}>
                                        <Text>{get(diagnose,'code.code')}</Text>
                                    </View>

                                    <View>
                                        <Text>{get(diagnose,'code.display')}</Text>
                                    </View>
                                </View>
                            </View></>)}
                    </View>
                    <View style={[styles.section, {
                        marginBottom: '10px', marginTop: '10px', borderBottom: '2px solid #000',
                        paddingBottom: '10px',
                        flexDirection: 'column'
                    }]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>5. Жарроҳлик амалиётлари:</Text>
                        {/* Table structure */}
                        <View style={[styles.table,{marginTop:'10px'}]}>

                            {/* Table Header */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Сана ва вақти</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Коди(ICHI)</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Асосий</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Номи</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Врач</Text>
                                </View>
                            </View>

                            {/* Table Rows */}
                            {get(data,'procedures',[]).map((_item, i) => (
                                <View style={styles.tableRow} key={i}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{get(_item,'proc_date')} {get(_item,'proc_time')}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{get(_item,'concept.class.code')}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{get(_item,'is_main') ? "Ҳа" :"Юқ"}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{get(_item,'concept.class.display')}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{get(_item,'patient.display')}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                        {/*procedures*/}
                    </View>
                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>6. Қон анализ натижалари:</Text>
                    </View>
                    {
                        get(data, 'observations', []).map((_item, index) => <View
                            style={[styles.section, {marginBottom: 0}, get(data, 'observations', []).length - 1 == index && {borderBottom: '2px solid #000'}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text>{get(_item, 'concept.name')}:</Text>
                            </View>
                            <View style={[{marginRight: '30px'}]}>
                                <Text>{get(_item, 'obs_date')}</Text>
                            </View>
                            <View>
                                <View style={[styles.row]}>
                                    <View style={[styles.row, {marginRight: '15px'}]}>
                                        <View
                                            style={[styles.checkbox, get(_item, 'value_code') == 'Negative' ? styles.checkbox.checked : '']}></View>
                                        <Text>Ижобий</Text>
                                    </View>
                                    <View style={[styles.row]}>
                                        <View
                                            style={[styles.checkbox, get(_item, 'value_code') != 'Negative' ? styles.checkbox.checked : '']}></View>
                                        <Text>Манфий</Text>
                                    </View>
                                </View>
                            </View>
                        </View>)
                    }
                    <View style={[styles.section, styles.flex.alignBottom, {
                        borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        <View style={[styles.section, {marginBottom: '10px', marginTop: '10px', marginRight: '10px'}]}>
                            <Text style={[styles.font.timesB, styles.text.lg]}>7. Тўлов ким томондан:</Text>
                        </View>
                        <View style={[styles.row]}>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox,get(data, 'discharge.coverage_type.code') == 'PUBLICPOL' ? styles.checkbox.checked : '']}></View>
                                <Text>ДТСЖ</Text>
                            </View>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox,get(data, 'discharge.coverage_type.code') == 'pay' ? styles.checkbox.checked : '']}></View>
                                <Text>Бемор томонидан</Text>
                            </View>
                            <View style={[styles.row, {marginRight: '15px'}]}>
                                <View
                                    style={[styles.checkbox,get(data, 'discharge.coverage_type.code') == 'SUBSIDMC' ? styles.checkbox.checked : '']}></View>
                                <Text>Бошқа маблағлар</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>8. Янги туғилган чақалоқ учун:</Text>
                    </View>
                    {get(data, 'newborns[0].weight') && <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>8.1.</Text><Text>Вазни:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'newborns[0].weight')} гр.</Text>
                        </View>
                    </View>}
                    {get(data, 'newborns[0].height') &&
                        <View style={[styles.section, {marginBottom: 0}]}>
                            <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                                <Text style={[styles.text.xs, {marginRight: '3px'}]}>8.2.</Text><Text>Бўйи:</Text>
                            </View>
                            <View>
                                <Text>{get(data, 'newborns[0].height')} см.</Text>
                            </View>
                        </View>}
                    {get(data, 'newborns[0].mother_pin') &&
                    <View style={[styles.section, {marginBottom: 0, borderBottom: '2px solid #000'}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>8.3.</Text><Text>Онаси ЖШШИР:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'newborns[0].mother_pin')} </Text>
                        </View>
                    </View>}

                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>9. Ўпка сил касаллигида дори
                            сезгирлиги:</Text>
                    </View>
                  <View style={[styles.row, {
                        flexWrap: 'wrap', borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        <View style={[styles.row, {marginRight: '15px'}]}>
                            <View
                                style={[styles.checkbox,get(data, 'drug_sensitivity.sensitive')  ? styles.checkbox.checked : '']}></View>
                            <Text>Сезгир</Text>
                        </View>
                        <View style={[styles.row, {marginRight: '15px'}]}>
                            <View
                                style={[styles.checkbox,get(data, 'drug_sensitivity.mono_resistant')  ? styles.checkbox.checked : '']}></View>
                            <Text>Монорезистентлик</Text>
                        </View>
                        <View style={[styles.row, {marginRight: '15px'}]}>
                            <View
                                style={[styles.checkbox,get(data, 'drug_sensitivity.poly_resistant')  ? styles.checkbox.checked : '']}></View>
                            <Text>Полирезистентлик</Text>
                        </View>
                        <View style={[styles.row, {marginRight: '15px', marginTop: '5px'}]}>
                            <View
                                style={[styles.checkbox,get(data, 'drug_sensitivity.multi_resistant')  ? styles.checkbox.checked : '']}></View>
                            <Text>Мултирезистентлик(МЛУ)</Text>
                        </View>
                        <View style={[styles.row, {marginRight: '15px', marginTop: '5px'}]}>
                            <View
                                style={[styles.checkbox,get(data, 'drug_sensitivity.extremely_resistant')  ? styles.checkbox.checked : '']}></View>
                            <Text>Ўта чидамлик (ШЛУ)</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>10. Вақтинча меҳнатга лаёқатсизлик
                            варақаси:</Text>
                    </View>
                    {get(data, 'sick_leave.start_date') && <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>10.1.</Text><Text>Очилган:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'sick_leave.start_date')}</Text>
                        </View>
                    </View>}
                    {get(data, 'sick_leave.end_date') && <View style={[styles.section, {
                        marginBottom: 0, borderBottom: '2px solid #000',
                        paddingBottom: '10px'
                    }]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '30%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>10.2.</Text><Text>Беркитилган:</Text>
                        </View>
                        <View>
                            <Text>{get(data, 'sick_leave.end_date')}</Text>
                        </View>
                    </View>}
                    <View style={[styles.section, {marginBottom: '10px', marginTop: '10px'}]}>
                        <Text style={[styles.font.timesB, styles.text.lg]}>11. Масъул шахслар:</Text>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '35%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>11.1.</Text><Text>Даволовчи
                            шифокор:</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={[styles.column, {justifyContent: 'center', alignItems: 'center'}]}>
                                <View style={styles.borderBottom}>
                                    <Text
                                        style={styles.text.sm}>{get(data, 'treatment.attender.display')}</Text>
                                </View>
                                <Text style={styles.text.xxs}>Ф.И.О.</Text>
                            </View>
                            <View style={[styles.column, {
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '15px'
                            }]}>
                                <Text>____________</Text>
                                <Text style={styles.text.xxs}>Имзо</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {marginBottom: 0}]}>
                        <View style={[styles.section, styles.flex.alignBottom, {width: '35%'}]}>
                            <Text style={[styles.text.xs, {marginRight: '3px'}]}>11.2.</Text><Text>Масъул шахс:</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={[styles.column, {justifyContent: 'center', alignItems: 'center'}]}>
                                <View style={styles.borderBottom}>
                                    <Text
                                        style={styles.text.sm}>{get(data, 'treatment.responsible.display')}</Text>
                                </View>
                                <Text style={styles.text.xxs}>Ф.И.О.</Text>
                            </View>
                            <View style={[styles.column, {
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '15px'
                            }]}>
                                <Text>____________</Text>
                                <Text style={styles.text.xxs}>Имзо</Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    );
};

export default Index;
