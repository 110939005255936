import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ChevronLeft} from "react-feather";
import Content from "../../../components/content";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {get, includes, isEmpty, isEqual} from "lodash";
import {KEYS} from "../../../constants/keys";
import {useGetOneQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {OverlayLoader} from "../../../components/loader";
import Locations from "../../../components/locations";
import Identifiers from "../../../components/identifiers";
import PatientContacts from "../../../components/contacts/patient-contacts";
import {FIELDS} from "../../../constants";

const PatientUpdateContainer = ({id}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [relationship, setRelationship] = useState(null)
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.patients, key: [KEYS.patients, id]})
    const {
        mutate: updatePatientRequest, isLoading: isLoadingPatient
    } = usePutQuery({listKeyId: KEYS.patients})
    const updatePatient = ({data: formData}) => {
        const {id: formId, ...rest} = formData
        updatePatientRequest({
            url: `${URLS.patients}/${id}`,
            attributes: {
                ...rest,
                // pin: get(head((get(get(data, 'data.payload.patient'), 'identifiers', []))), 'value')
            }
        }, {
            onSuccess: () => {
                navigate('/patient')
            }
        })
    }
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/patient'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12 mb-4">
                    <Title>{t("Обновить информацию о пациенте")}</Title>
                </div>
                <div className="col-span-12">
                    {(isLoadingPatient) && <OverlayLoader/>}
                    <Content sm>

                        <Form defaultValues={{
                            ...get(data, 'data.payload.patient'),
                            telecoms: isEmpty(get(data, 'data.payload.patient.telecoms', [])) ? [{}] : [{}]
                        }} formRequest={(data) => updatePatient(data)}
                              name={'patientForm'}
                              classNames={'grid grid-cols-12 gap-x-6 mt-3'} footer={<div className={'col-span-12 '}>
                            <div className="flex">
                                <button type={'submit'}
                                        className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                    {t('Сохранить')}
                                </button>
                            </div>
                        </div>}>
                            <div className="col-span-12">
                                <Title sm className={'mb-3'}>{t("Удостоверяющий личность документ")}</Title>
                            </div>
                            <Identifiers/>
                            <div className="col-span-12">
                                <Title sm className={'mb-3'}>{t("Основные данные")}</Title>
                            </div>
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'first_name')}
                                   classNames={'col-span-4'}
                                   name={'first_name'}
                                   placeholder={t('Имя')}
                                   property={{type: 'text'}}
                                   label={t('Имя')}
                            />
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'last_name')}
                                   classNames={'col-span-4'}
                                   name={'last_name'}
                                   placeholder={t('Фамилия')}
                                   property={{type: 'text'}}
                                   label={t('Фамилия')}
                            />
                            <Field type={FIELDS.input} params={{
                                required: true,
                            }}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'middle_name')}
                                   classNames={'col-span-4'}
                                   name={'middle_name'}
                                   placeholder={t('Отчество')}
                                   property={{type: 'text'}}
                                   label={t('Отчество')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'nationality')}
                                   url={URLS.nationality}
                                   keyId={KEYS.nationality}
                                   classNames={'col-span-4'}
                                   name={'nationality'}
                                   limit={1000}
                                   label={t('Национальность')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'citizenship')}
                                   url={URLS.citizenship}
                                   keyId={KEYS.citizenship}
                                   classNames={'col-span-4'}
                                   name={'citizenship'}
                                   limit={1000}
                                   label={t('Гражданство')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'birth_country')}
                                   url={URLS.citizenship}
                                   keyId={KEYS.citizenship}
                                   classNames={'col-span-4'}
                                   name={'birth_country'}
                                   limit={1000}
                                   label={t('Страна рождения')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.datepicker}
                                   dateFormat={"yyyy-MM-dd"}
                                   params={{
                                       required: true,
                                   }}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'birth_date')}
                                   classNames={'col-span-2'}
                                   name={'birth_date'}
                                   placeholder={t('Дата рождения')}
                                   label={t('Дата рождения')}
                            />
                            <Field type={FIELDS.input}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'birth_place')}
                                   classNames={'col-span-10'}
                                   name={'birth_place'}
                                   placeholder={t('Место рождения')}
                                   property={{type: 'text'}}
                                   label={t('Место рождения')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   defaultValue={get(get(data, 'data.payload.patient'), 'gender')}
                                   url={URLS.gender}
                                   keyId={KEYS.gender}
                                   classNames={'col-span-4'}
                                   name={'gender'}
                                   limit={1000}
                                   label={t('Пол')}
                                   params={{required: true}}
                            />
                            <div className={'col-span-12'}>
                                <hr className={'my-4'}/>
                            </div>
                            <div className="col-span-12">
                                <Title sm className={'mb-3'}>{t("Адрес")}</Title>
                            </div>
                            <Locations dataKey={'addresses'} data={get(data, 'data.payload.patient')}
                                       name={'addresses'}/>
                            <div className={'col-span-12'}>
                                <hr className={'my-4'}/>
                            </div>
                            <div className="col-span-12">
                                <Title sm className={'mb-3'}>{t("Контакты")}</Title>
                            </div>
                            <PatientContacts hasSubtitle={false} data={{...get(data, 'data.payload.patient')}}/>
                            <Field type={FIELDS.asyncSelect}
                                   url={URLS.contactRelationship}
                                   keyId={KEYS.contactRelationship}
                                   property={{onChange:(val)=>setRelationship(get(val,'code'))}}
                                   classNames={'col-span-3'}
                                   name={'contacts[0].relationship'}
                                   limit={1000}
                                   label={t('Relationship')}
                                   params={{required: true}}
                            />
                            {includes(["NUR","PH"],relationship) && <Field type={FIELDS.asyncSelect}
                                                                           url={URLS.practitionerRoleList}
                                                                           keyId={KEYS.practitionerRoleList}
                                                                           classNames={'col-span-3'}
                                                                           name={'contacts[0].practitioner_role'}
                                                                           limit={1000}
                                                                           defaultValue={get(data, 'data.payload.patient.contacts[0].practitioner_role')}
                                                                           label={t('Practitioner role')}
                                                                           params={{required: true}}
                            />}
                            {
                                isEqual(relationship,'N') && <Field type={FIELDS.inputMask}
                                                                    params={{required: true, pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                                                                    classNames={'col-span-4'}
                                                                    name={'contacts[0].pin'}
                                                                    property={{
                                                                        placeholder: t('ПИНФЛ'),
                                                                        mask: '99999999999999',
                                                                        maskChar: '_',
                                                                    }}
                                                                    label={t('ПИНФЛ')}
                                />
                            }
                            <Field type={FIELDS.input}
                                   classNames={'col-span-3'}
                                   name={'contacts[0].first_name'}
                                   label={t('First name')}
                                   params={{required: true}}
                            />
                            <Field type={FIELDS.input}
                                   classNames={'col-span-3'}
                                   name={'contacts[0].last_name'}
                                   label={t('Last name')}
                                   params={{required: true}}
                            />

                            <Field type={FIELDS.phoneNumber}
                                   classNames={'col-span-3'} name={`contacts[0].phone`}
                                   params={{
                                       valueAsString: true,
                                       required: true,
                                       pattern: {
                                           value: /^(33|36|55|61|62|65|66|67|69|70|71|72|73|74|75|76|77|78|79|88|90|91|93|94|95|97|98|99)\d{7}$/,
                                           message: 'Invalid format'
                                       }
                                   }}
                                   defaultValue={get(data, 'data.payload.patient.contacts[0].phone')}
                                   placeholder={t('Телефон')}
                                   label={t('Телефон')}
                            />
                        </Form>
                    </Content>
                </div>
            </div>
        </>
    );
}

export default PatientUpdateContainer;
