import React, {useEffect} from 'react';
import clsx from "clsx";
import {get, hasIn, isFunction, isNull} from "lodash";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import Label from "../../../components/label";

const Input = ({
                   name = 'name',
                   property,
                   params,
                   label = '',
                   classNames = '',
                   defaultValue = null,
                   ...rest
               }) => {
    const {t} = useTranslation()
    const {register, getValues, watch, formState: {errors}, setValue} = useFormContext()
    const isError = hasIn(errors, name);
    useEffect(() => {
        if (isFunction(get(property, 'onChange'))) {
            get(property, 'onChange')(getValues(name), name)
        }
    }, [watch(name)]);

    useEffect(() => {
        if (!isNull(defaultValue)) {
            setValue(name, defaultValue)
        }
    }, [defaultValue])
    return (
        <>
            <div className={clsx("form-group", classNames, {'!mb-0': get(property, 'type') === 'hidden'})}>
                {get(property, 'type') !== 'hidden' && <Label isError={isError} isRequired={get(params,'required',false)}>{label ?? name}</Label>}
                <input defaultValue={defaultValue}  {...register(name, {...params})} {...rest}
                       className={clsx('form-input', {'border-red-600': hasIn(errors, name)})}
                       type={get(property, 'type', "text")} disabled={get(property, 'disabled', false)}
                       step={get(property, "step", "any")} max={get(property, "max", 1000000000)} min={0}/>
                {get(property, 'type') !== "hidden" && <>{get(errors, `${name}.type`) === 'required' &&
                    <span className={'form-error'}>{t('Заполните обязательное поле')}</span>}
                    {get(errors, `${name}.type`) === 'validation' &&
                        <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
                    {get(errors, `${name}.type`) === 'pattern' &&
                        <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
                    {get(errors, `${name}.type`) === 'max' &&
                        <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
                </>}

            </div>
        </>
    );
};

export default Input;
