import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ChevronLeft, Edit2, FileText, Plus} from "react-feather";
import Title from "../../../components/title";
import {get, includes, isEmpty, isEqual, orderBy} from "lodash";
import Content from "../../../components/content";
import {useGetOneQuery, usePostQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import Swal from "sweetalert2";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Form from "../../../containers/form";
import DiagnosesForm from "../../../components/diagnoses-form";
import {KEYS} from "../../../constants/keys";
import ProcedureForm from "../../../components/procedure-form";
import ObservationForm from "../../../components/observation-form";
import NewbornForm from "../../../components/newborn-form";
import dayjs from "dayjs";
import clsx from "clsx";
import {PDFDownloadLink} from '@react-pdf/renderer';
import Pdf from "../../../components/pdf"
import {FIELDS} from "../../../constants";
import Field from "../../../containers/form/field";
import {PDFViewer} from '@react-pdf/renderer';


const Patient066ViewContainer = ({id = null}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [openUpdateDiagnoses, setOpenUpdateDagnoses] = useState(false)
    const [openUpsertProcedure, setOpenUpsertProcedure] = useState(false)
    const [openUpsertObservation, setOpenUpsertObservation] = useState(false)
    const [openUpsertNewborn, setOpenUpsertNewborn] = useState(false)
    const [openDischarge, setOpenDischarge] = useState(false)
    const [dischargeStatus, setDischargeStatus] = useState(null)
    const [openDirection, setOpenDirection] = useState(false)

    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.encounterImp})
    const {
        mutate: updateDiagnoseRequest, isLoading: isLoadingPut
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: upsertProcedureRequest, isLoading: isLoadingUpsertProcedure
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: upsertObservationRequest, isLoading: isLoadingUpsertObservation
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: upsertNewbornRequest, isLoading: isLoadingUpsertNewborn
    } = usePutQuery({listKeyId: KEYS.encounterImp})


    const {
        mutate: deactivateRequest
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: finishRequest
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: dischargeRequest, isLoading: isLoadingDischarge
    } = usePutQuery({listKeyId: KEYS.encounterImp})
    const {
        mutate: serviceRequest, isLoading: isLoadingServiceRequest
    } = usePostQuery({listKeyId: KEYS.encounterImp})

    const deactivate = () => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: t('Вы действительно хотите отменить запись?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
        }).then((result) => {
            if (result.isConfirmed) {
                deactivateRequest({url: `${URLS.encounterImp}/${id}/entered-in-error`})
            }
        });
    }
    const finish = () => {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: t('Вы действительно хотите закончить запись?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
        }).then((result) => {
            if (result.isConfirmed) {
                finishRequest({url: `${URLS.encounterImp}/${id}/finished`})
            }
        });
    }
    const updateDiagnose = ({data: attrs}) => {
        updateDiagnoseRequest({url: `${URLS.encounterImp}/${id}/diagnoses`, attributes: {...attrs}}, {
            onSuccess: () => {
                setOpenUpdateDagnoses(false)
            }
        })
    }
    const upsertProcedure = ({data: attrs}) => {
        const {procedures = []} = attrs;
        upsertProcedureRequest({
            url: URLS.proceduresUpsert,
            attributes: {
                procedures: procedures.map(({encounter_id, proc_date, ...rest}) => ({
                    ...rest,
                    encounter_id: id,
                    proc_date: dayjs(proc_date).format("YYYY-MM-DD"),
                    proc_time: dayjs(proc_date).format("HH:mm")
                }))
            }
        }, {
            onSuccess: () => {
                setOpenUpsertProcedure(false)
            }
        })
    }
    const upsertObservation = ({data: attrs}) => {
        const {observations = []} = attrs;
        upsertObservationRequest({
            url: URLS.observationsUpsert,
            attributes: {
                observations: observations.map((item) => ({
                    ...item,
                    encounter_id: id
                }))
            }
        }, {
            onSuccess: () => {
                setOpenUpsertObservation(false)
            }
        })
    }
    const upsertNewborn = ({data: attrs}) => {
        const {newborns = []} = attrs;
        upsertNewbornRequest({
            url: `${URLS.encounterImp}/${id}/newborns/upsert`,
            attributes: {
                newborns: newborns.map(({birth_date, ...rest}) => ({
                    ...rest,
                    birth_date: dayjs(birth_date).format("YYYY-MM-DD"),
                    birth_time: dayjs(birth_date).format("HH:mm")
                }))
            }
        }, {
            onSuccess: () => {
                setOpenUpsertNewborn(false)
            }
        })
    }
    const discharge = ({data: attrs}) => {
        dischargeRequest({
            url: `${URLS.encounterImp}/${id}/discharge`,
            attributes: {
                ...attrs
            }
        }, {
            onSuccess: () => {
                setOpenDischarge(false)
            }
        })
    }
    const servicePost = ({data: attrs}) => {
        serviceRequest({
            url: `${URLS.encounterImp}/${id}/service-request`,
            attributes: {
                ...attrs
            }
        }, {
            onSuccess: () => {
                setOpenDirection(false);
            }
        })
    }
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-3">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/encounters/imp'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12">
                    {/*<PDFViewer className={'w-full h-screen'}>*/}
                    {/*    <Pdf data={get(data, 'data.payload.encounter')}/>*/}
                    {/*</PDFViewer>*/}
                    <Content sm>
                        <div className="grid grid-cols-12 mb-6">
                            <div className="col-span-6">
                                <Link to={`/patient/view/${get(data, 'data.payload.encounter.patient.id')}`}><Title
                                    sm>{get(data, 'data.payload.encounter.patient.display')}</Title></Link>
                            </div>
                            <div className="col-span-6 text-right flex justify-end ">
                                {includes(['finished'], get(data, 'data.payload.encounter.status.code')) && <button
                                    className={'inline-flex items-center py-2 px-4 text-sm font-bold text-white rounded-lg mr-3 bg-[#006D85]'}>
                                    <PDFDownloadLink document={<Pdf data={get(data, 'data.payload.encounter')}/>}
                                                     fileName={`${get(data, 'data.payload.encounter.patient.display')}-066.pdf`}>
                                        {({loading}) => (loading ? 'Loading..' : <FileText/>)}
                                    </PDFDownloadLink>
                                </button>}
                                {!includes(['entered-in-error'], get(data, 'data.payload.encounter.status.code')) && <>
                                    <button
                                        onClick={deactivate}
                                        className={'inline-flex items-center py-2 px-4 text-sm font-bold text-white rounded-lg mr-3 bg-[#C5414D]'}>
                                        {t("Деактивировать")}
                                    </button>
                                </>}
                                {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) && <>
                                    {get(data, 'data.payload.encounter.discharge') && <button
                                        onClick={finish}
                                        className={'inline-flex items-center py-2 px-4 text-sm font-bold text-white rounded-lg mr-3 bg-[#FFC107] min-w-[150px] justify-center'}>
                                        {t("Finish")}
                                    </button>}

                                    <button
                                        onClick={() => navigate(`/patient/066/update/${get(data, 'data.payload.encounter.patient.id')}/${id}`)}
                                        className={'inline-flex items-center py-2 px-4 text-sm font-bold text-primary border border-primary rounded-lg'}>
                                        <Edit2 size={18} className={'mr-3'}/>
                                        {t("Редактировать данные")}
                                    </button>
                                </>}
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-x-8 items-start">
                            <div className="col-span-6 rounded shadow-card p-6 ">
                                <Title sm>{t('Поступление в стационар')}</Title>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("ID")}:</span>
                                    <strong className={'w-2/3'}>{get(data, 'data.payload.encounter.id')}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Статус")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.status.display')}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Организация")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.organization.display', '-')}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Класс")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.class.display')}</strong>
                                </div>
                                {/*<div className={'flex py-5 border-b items-center'}>*/}
                                {/*    <span className={'w-1/3'}>{t("Статус направления")}:</span>*/}
                                {/*    <strong className={'w-2/3'}>-</strong>*/}
                                {/*</div>*/}

                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3 pr-2'}>{t("Неотложная помощь")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.admission.emergency') ? t('Да') : t("Нет")}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3 pr-3'}>{t("Прибытие на автомашине скорой помощи")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.admission.by_ambulance') ? t('Да') : t("Нет")}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span
                                        className={'w-1/3 pr-3'}>{t("Госпитализация по поводу данного заболевания в течение 30 дней")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.admission.repeated_within_30d') ? t('Да') : t("Нет")}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Поступление пациента")}:</span>
                                    <strong
                                        className={'w-2/3'}>{get(data, 'data.payload.encounter.admission.priority.display')}</strong>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Диагноз при поступлении")}:</span>
                                    <div className={'w-2/3'}>
                                        <table
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Тип диагноза")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.admission.admission_diagnosis.rubric.display')}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Диагноз")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.admission.admission_diagnosis.code.display')}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Verification status")}</th>
                                                <td className={'border p-3  w-2/3'}>{get(data, 'data.payload.encounter.admission.admission_diagnosis.verification_status.display')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Категория льготности")}:</span>
                                    <strong className={'w-2/3'}>-</strong>
                                </div>
                            </div>
                            <div className="col-span-6 min-h-full">
                                <div className={'rounded shadow-card p-6'}>
                                    <Title sm>{t('Лечение')}</Title>
                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Отделение")}:</span>
                                        <strong
                                            className={'w-2/3'}>{get(data, 'data.payload.location.display', '-')}</strong>
                                    </div>
                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Дата прибытия")}:</span>
                                        <strong
                                            className={'w-2/3'}>{dayjs(get(data, 'data.payload.encounter.admission.admission_date')).format("YYYY-MM-DD")}</strong>
                                    </div>
                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Дата выписки")}:</span>
                                        <strong
                                            className={'w-2/3'}>{get(data, 'data.payload.encounter.discharge.discharge_date', '-')}</strong>
                                    </div>

                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Диагноз")}:</span>
                                        <div className={'w-2/3'}>
                                            {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                            <Button onClick={() => setOpenUpdateDagnoses(true)}
                                                    classNames={'mb-3 bg-primary text-white text-sm !py-1.5 font-semibold'}>
                                                <Edit2 size={16} className={'mr-1.5'}/>{t("Изменить диагноз")}
                                            </Button>}
                                            {orderBy(get(data, 'data.payload.encounter.diagnoses', []), ['id'], ['ASC']).map(item =>
                                                <table
                                                    key={get(item, 'id')}
                                                    className={'border  border-collapse text-center w-full mb-1'}>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Тип диагноза")}</th>
                                                        <td className={'border p-3 w-2/3'}>{get(item, 'rubric.display')}</td>
                                                    </tr>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Диагноз")}</th>
                                                        <td className={'border p-3 w-2/3'}>{get(item, 'code.display')}</td>
                                                    </tr>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Verification status")}</th>
                                                        <td className={'border p-3  w-2/3'}>{get(item, 'verification_status.display')}</td>
                                                    </tr>
                                                </table>)
                                            }
                                        </div>
                                    </div>
                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Хирургические операции")}:</span>
                                        <div
                                            className={'w-2/3'}>
                                            {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                            <Button
                                                onClick={() => setOpenUpsertProcedure(true)}
                                                classNames={' bg-primary text-white text-sm !py-1.5 font-semibold mb-3'}>
                                                {isEmpty(get(data, 'data.payload.encounter.procedures', [])) ? <>
                                                    <Plus
                                                        size={16}
                                                        className={'mr-1.5'}/>{t("Добавить хирургическое вмешательство")}</> : <>
                                                    <Edit2 size={16}
                                                           className={'mr-1.5'}/>{t("Изменить хирургическое вмешательство")}</>}
                                            </Button>}
                                            {orderBy(get(data, 'data.payload.encounter.procedures', []), ['id'], ['ASC']).map(item =>
                                                <table
                                                    key={get(item, 'id')}
                                                    className={'border  border-collapse text-center w-full mb-1'}>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Concept")}</th>
                                                        <td className={'border p-3 w-2/3'}>{get(item, 'concept.display')}</td>
                                                    </tr>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Время операции")}</th>
                                                        <td className={'border p-3 w-2/3'}>{get(item, 'proc_date')} {get(item, 'proc_time')}</td>
                                                    </tr>
                                                    <tr className={'tr'}>
                                                        <th className={'border p-3 w-1/3'}>{t("Это главное?")}</th>
                                                        <td className={'border p-3  w-2/3'}>{get(item, 'is_main') ? t('Да') : t('Нет')}</td>
                                                    </tr>
                                                </table>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'rounded shadow-card p-6 mt-6'}>
                                    <Title sm>{t('Направления')}</Title>
                                    <div className={'flex py-5 border-b items-center'}>
                                        <span className={'w-1/3'}>{t("Есть направления?")}</span>
                                        <div
                                            className={'w-2/3'}>
                                            {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                            <Button onClick={() => setOpenDirection(true)}
                                                    classNames={'mb-3 bg-primary text-white text-sm !py-1.5 font-semibold'}>
                                                <Edit2 size={16} className={'mr-1.5'}/> {t("Изменить")}
                                            </Button>}
                                            {get(data, 'data.payload.encounter.service_request') ?
                                            <table
                                                key={get(data, 'id')}
                                                className={'border  border-collapse text-center w-full mb-1'}>
                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("Organization")}</th>
                                                    <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.service_request.referrer_organization.display')}</td>
                                                </tr>

                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("Диагноз МКБ-10")}</th>
                                                    <td className={'border p-3 w-2/3 '}>({get(data, 'data.payload.encounter.service_request.reason_condition.code.code', '-')}) {get(data, 'data.payload.encounter.service_request.reason_condition.code.display', '-')}

                                                    </td>
                                                </tr>
                                            </table>:<div>{t('Нет')}</div>
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-span-6 rounded shadow-card p-6 mt-8">
                                <Title sm>{t('Дополнительная информация')}</Title>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Результаты анализа крови")}:</span>
                                    <div
                                        className={'w-2/3'}>  {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                    <Button
                                        onClick={() => setOpenUpsertObservation(true)}
                                        classNames={' bg-primary text-white text-sm !py-1.5 font-semibold mb-3'}>
                                        {isEmpty(get(data, 'data.payload.encounter.observations', [])) ? <><Plus
                                            size={16}
                                            className={'mr-1.5'}/>{t("Добавить результат анализа крови")}</> : <>
                                            <Edit2 size={16}
                                                   className={'mr-1.5'}/>{t("Изменить результат анализа крови")}</>}
                                    </Button>}
                                        {orderBy(get(data, 'data.payload.encounter.observations', []), ['id'], ['ASC']).map(item =>
                                            <table
                                                key={get(item, 'id')}
                                                className={'border  border-collapse text-center w-full mb-1'}>
                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("Category")}</th>
                                                    <td className={'border p-3 w-2/3'}>{get(item, 'category.display')}</td>
                                                </tr>
                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("Analyze type")}</th>
                                                    <td className={'border p-3 w-2/3'}>{get(item, 'concept.display')}</td>
                                                </tr>
                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("Analyze result")}</th>
                                                    <td className={'border p-3 w-2/3'}>{get(item, 'value_code.display')}</td>
                                                </tr>
                                                <tr className={'tr'}>
                                                    <th className={'border p-3 w-1/3'}>{t("obs_date")}</th>
                                                    <td className={'border p-3 w-2/3'}>{get(item, 'obs_date')}</td>
                                                </tr>
                                            </table>)
                                        }
                                    </div>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Для новорожденного")}:</span>
                                    <div
                                        className={'w-2/3'}>  {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                    <Button
                                        onClick={() => setOpenUpsertNewborn(true)}
                                        classNames={' bg-primary text-white text-sm !py-1.5 font-semibold mb-3'}>
                                        {isEmpty(get(data, 'data.payload.encounter.newborns', [])) ? <><Plus
                                            size={16}
                                            className={'mr-1.5'}/>{t("Добавить новорожденного")}</> : <>
                                            <Edit2 size={16}
                                                   className={'mr-1.5'}/>{t("Редактировать новорожденный")}</>}
                                    </Button>}
                                        {get(data, 'data.payload.encounter.newborns', []).map(item => <table
                                            key={get(item, 'id')}
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Вес")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(item, 'weight')}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Рост")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(item, 'height')}</td>
                                            </tr>
                                        </table>)
                                        }
                                    </div>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                        <span
                                            className={'w-1/3 pr-2'}>{t("Чувствительность к лекарствам при заболеваниях легких")}:</span>
                                    <div
                                        className={'w-2/3'}>
                                        <table
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Sensitive")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.drug_sensitivity.sensitive') ? t('Да') : t("Нет")}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Poly resistant")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.drug_sensitivity.poly_resistant') ? t('Да') : t("Нет")}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Multi resistant")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.drug_sensitivity.multi_resistant') ? t('Да') : t("Нет")}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Mono resistant")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.drug_sensitivity.mono_resistant') ? t('Да') : t("Нет")}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Extremely resistant")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.drug_sensitivity.extremely_resistant') ? t('Да') : t("Нет")}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Справка о временной нетрудоспособности")}:</span>
                                    <div
                                        className={'w-2/3'}>
                                        <table
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Дата открытия")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.sick_leave.start_date')}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Дата закрытия")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.sick_leave.end_date')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Ответственное лицо")}:</span>
                                    <div
                                        className={'w-2/3'}>
                                        <table
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Лечащий врач")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.treatment.attender.display')}</td>
                                            </tr>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Ответственное лицо")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.treatment.responsible.display')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6 rounded shadow-card p-6 mt-8">
                                <Title sm>{t('Результат госпитализации')}</Title>
                                <div className={'flex py-5 border-b items-center'}>
                                    <span className={'w-1/3'}>{t("Результат госпитализации")}:</span>
                                    <div
                                        className={'w-2/3'}>
                                        {!includes(['finished', 'entered-in-error'], get(data, 'data.payload.encounter.status.code')) &&
                                        <Button onClick={() => setOpenDischarge(true)}
                                                classNames={'mb-3 bg-primary text-white text-sm !py-1.5 font-semibold'}>
                                            <Edit2 size={16} className={'mr-1.5'}/> {t("Изменить")}
                                        </Button>}
                                        {get(data, 'data.payload.encounter.discharge.discharge_status.display') &&
                                        <table
                                            key={get(data, 'id')}
                                            className={'border  border-collapse text-center w-full mb-1'}>
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Результат госпитализации")}</th>
                                                <td className={'border p-3 w-2/3'}>{get(data, 'data.payload.encounter.discharge.discharge_status.display')}</td>
                                            </tr>
                                            {get(data, 'data.payload.encounter.discharge.death_cause.direct_cause.code.code') &&
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Диагноз МКБ-10")}</th>
                                                <td className={'border p-3 w-2/3 text-left'}>({get(data, 'data.payload.encounter.discharge.death_cause.direct_cause.code.code', '-')}) {get(data, 'data.payload.encounter.discharge.death_cause.direct_cause.code.display', '-')}
                                                    <br/> ({get(data, 'data.payload.encounter.discharge.death_cause.underlying_cause.code.code', '-')}) {get(data, 'data.payload.encounter.discharge.death_cause.underlying_cause.code.display', '-')}
                                                </td>
                                            </tr>}
                                            <tr className={'tr'}>
                                                <th className={'border p-3 w-1/3'}>{t("Время")}</th>
                                                <td className={'border p-3  w-2/3'}>{get(data, 'data.payload.encounter.discharge.discharge_date')}</td>
                                            </tr>
                                        </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </div>
                <Modal open={openUpdateDiagnoses} onClose={() => setOpenUpdateDagnoses(null)} classNames={'!w-[1000px]'}
                       title={t('Изменить диагноз')}>
                    <Form defaultValues={{
                        diagnoses: orderBy(get(data, 'data.payload.encounter.diagnoses', []), ['id'], ['ASC']).map(_diagnose => ({
                            id: get(_diagnose, 'id', undefined),
                            rubric: get(_diagnose, 'rubric', ''),
                            code: {id: get(_diagnose, 'code.id', ''), display: get(_diagnose, 'code.display', '')},
                            verification_status: get(_diagnose, 'verification_status')
                        }))
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button onClick={() => setOpenUpdateDagnoses(false)} type={'button'}
                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                {t('Отмена')}
                            </button>
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                {t('Сохранить')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6'} formRequest={updateDiagnose}>
                        {isLoadingPut && <ContentLoader/>}
                        <DiagnosesForm/>
                    </Form>
                </Modal>
                <Modal open={openUpsertProcedure} onClose={() => setOpenUpsertProcedure(null)}
                       classNames={clsx('!w-[1000px] ', {'overflow-unset': isEmpty(get(data, 'data.payload.encounter.procedures', []))})}
                       title={isEmpty(get(data, 'data.payload.encounter.procedures', [])) ? t('Добавить хирургическое вмешательство') : t('Изменить хирургическое вмешательство')}>
                    <Form defaultValues={{
                        procedures: isEmpty(get(data, 'data.payload.encounter.procedures', [])) ? [{
                            concept: null,
                            is_main: isEmpty(get(data, 'data.payload.encounter.procedures', [])),
                            proc_date: new Date(),
                        }] : orderBy(get(data, 'data.payload.encounter.procedures', []), ['id'], ['ASC']).map(_procedure => ({
                            id: get(_procedure, 'id', undefined),
                            concept: {
                                id: get(_procedure, 'concept.id', ''),
                                display: get(_procedure, 'concept.name', '')
                            },
                            is_main: get(_procedure, 'is_main', false),
                            proc_date: get(_procedure, 'proc_time') ? dayjs(`${get(_procedure, 'proc_date')} ${get(_procedure, 'proc_time')}`) : dayjs(`${get(_procedure, 'proc_date')}`),
                        }))
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button onClick={() => setOpenUpsertProcedure(false)} type={'button'}
                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block  font-bold text-center  mt-6'}>
                                {t('Отмена')}
                            </button>
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                {t('Сохранить')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6 '} formRequest={upsertProcedure}>
                        {isLoadingUpsertProcedure && <ContentLoader/>}
                        <ProcedureForm/>
                    </Form>
                </Modal>

                <Modal open={openUpsertObservation} onClose={() => setOpenUpsertObservation(false)}
                       classNames={clsx('!w-[1000px] ', {'overflow-unset': isEmpty(get(data, 'data.payload.encounter.observations', []))})}
                       title={isEmpty(get(data, 'data.payload.encounter.observations ', [])) ? t('Добавить результат анализа крови') : t('Изменить результат анализа крови')}>
                    <Form defaultValues={{
                        observations: isEmpty(get(data, 'data.payload.encounter.observations', [])) ? [{
                            category: {id: 4},
                            concept: null,
                            value_code: null,
                            obs_date: new Date(),
                        }] : orderBy(get(data, 'data.payload.encounter.observations', []), ['id'], ['ASC']).map(_observation => ({
                            id: get(_observation, 'id', undefined),
                            category: get(_observation, 'category'),
                            concept: get(_observation, 'concept'),
                            value_code: get(_observation, 'value_code'),
                            obs_date: get(_observation, 'obs_date'),
                        }))
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button onClick={() => setOpenUpsertObservation(false)} type={'button'}
                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block  font-bold text-center  mt-6'}>
                                {t('Отмена')}
                            </button>
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                {t('Сохранить')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6 '} formRequest={upsertObservation}>
                        {isLoadingUpsertObservation && <ContentLoader/>}
                        <ObservationForm/>
                    </Form>
                </Modal>
                <Modal open={openUpsertNewborn} onClose={() => setOpenUpsertNewborn(null)}
                       classNames={clsx('!w-[1000px] ', {'overflow-unset': isEmpty(get(data, 'data.payload.encounter.procedures', []))})}
                       title={isEmpty(get(data, 'data.payload.encounter.newborns ', [])) ? t('Добавить новорожденного') : t('Изменить результат анализа крови')}>
                    <Form defaultValues={{
                        newborns: isEmpty(get(data, 'data.payload.encounter.newborns', [])) ? [{
                            mother_pin: get(data, 'data.payload.encounter.patient.pin'),
                            weight: null,
                            height: null,
                            birth_date: new Date(),
                        }] : get(data, 'data.payload.encounter.newborns', []).map(_newborn => ({
                            id: get(_newborn, 'id', undefined),
                            mother_pin: get(_newborn, 'mother_pin'),
                            weight: get(_newborn, 'weight'),
                            height: get(_newborn, 'height'),
                            birth_date: get(_newborn, 'birth_date'),
                        }))
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button onClick={() => setOpenUpsertNewborn(false)} type={'button'}
                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block  font-bold text-center  mt-6'}>
                                {t('Отмена')}
                            </button>
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                {t('Сохранить')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6 '} formRequest={upsertNewborn}>
                        {isLoadingUpsertNewborn && <ContentLoader/>}
                        <NewbornForm/>
                    </Form>
                </Modal>
                <Modal open={openDischarge} onClose={() => setOpenDischarge(false)} classNames={'!w-[1000px]'}
                       title={t('Завершить работу')}>
                    <Form defaultValues={{
                        ...get(data, 'data.payload.encounter.discharge', {})
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button onClick={() => setOpenDischarge(false)} type={'button'}
                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                {t('Нет')}
                            </button>
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                {t('Да')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6'} formRequest={discharge}>
                        {isLoadingDischarge && <ContentLoader/>}
                        <Field params={{
                            required: true,
                        }} classNames={'col-span-4'} type={FIELDS.asyncSelect}
                               dataKey={'payload.data'}
                               name={'coverage_type'}
                               label={t('Coverage type')}
                               url={URLS.coverageType}
                               keyId={KEYS.coverageType}/>
                        <Field params={{
                            required: true,
                        }} classNames={'col-span-4'} type={FIELDS.asyncSelect}
                               dataKey={'payload.data'}
                               name={'discharge_status'}
                               label={t('Discharge status')}
                               url={URLS.dischargeDisposition}
                               property={{onChange: (val) => setDischargeStatus(get(val, 'code'))}}
                               keyId={KEYS.dischargeDisposition}/>
                        {isEqual(dischargeStatus, 'exp') && <>
                            <Field type={FIELDS.treeSelect}
                                   key={KEYS.icd10}
                                   params={{required: true}}
                                   urlSearchParam={{name: get(data, 'data.payload.encounter.discharge.death_cause.direct_cause.code.display')}}
                                   url={URLS.icd10}
                                   classNames={'col-span-4'} name={`death_cause.direct_cause.code.id`}
                                   label={t('Death direct cause')}
                            />
                            <Field type={FIELDS.treeSelect}
                                   key={KEYS.icd10}
                                   params={{required: true}}
                                   urlSearchParam={{name: get(data, 'data.payload.encounter.discharge.death_cause.underlying_cause.code.display')}}
                                   url={URLS.icd10}
                                   classNames={'col-span-4'} name={`death_cause.underlying_cause.code.id`}
                                   label={t('Death underlying cause')}
                            />
                        </>
                        }
                        <Field
                            dateFormat={"yyyy-MM-dd"}
                            type={FIELDS.datepicker}
                            classNames={'col-span-4'}
                            name={'discharge_date'}
                            params={{
                                required: true,
                            }}
                            label={t('Discharge Date')}
                        />
                        <Field
                            type={FIELDS.textarea}
                            classNames={'col-span-12'}
                            name={'note'}
                            label={t('Notes')}
                        />
                    </Form>
                </Modal>
                <Modal open={openDirection} onClose={() => setOpenDirection(false)} classNames={'!w-[1000px]'}
                       title={t('Изменить направления')}>
                    <Form defaultValues={{
                        ...get(data, 'data.payload.encounter.service_request', {})
                    }} footer={<div className={'col-span-12 '}>
                        <div className="flex justify-end">
                            <button type={'submit'}
                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-2'}>
                                {t('Save')}
                            </button>
                        </div>
                    </div>} classNames={'grid grid-cols-12 gap-x-6 mt-3'} formRequest={servicePost}>
                        {isLoadingServiceRequest && <ContentLoader/>}
                        <Field params={{
                            required: true,
                        }} classNames={'col-span-6'} type={FIELDS.asyncSelect}
                               dataKey={'payload.data'}
                               name={'referrer_organization'}
                               label={t('Organization')}
                               url={URLS.organizations}
                               keyId={KEYS.organizations}/>
                        <Field type={FIELDS.treeSelect}
                               key={KEYS.icd10}
                               defaultValue={get(data, 'data.payload.encounter.service_request.reason_condition.code.id')}
                               urlSearchParam={{name: get(data, 'data.payload.encounter.service_request.reason_condition.code.display')}}
                               url={URLS.icd10}
                               params={{required: true}}
                               classNames={'col-span-6'} name={`reason_condition.code.id`}
                               label={t('Диагноз')}
                        />
                    </Form>
                </Modal>

            </div>

        </>
    );
}

export default Patient066ViewContainer;
