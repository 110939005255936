import React from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ChevronLeft} from "react-feather";
import Content from "../../../components/content";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {KEYS} from "../../../constants/keys";
import {useGetOneQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {FIELDS} from "../../../constants";
import Title from "../../../components/title";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import {get} from "lodash"
import dayjs from "dayjs";
import DiagnosesForm from "../../../components/diagnoses-form";
import ProcedureForm from "../../../components/procedure-form";
import ObservationForm from "../../../components/observation-form";
import NewbornForm from "../../../components/newborn-form";
import {Panel} from "rsuite";


const Patient066UpdateContainer = ({id = null, patient_id = null}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.encounterImp})
    const {
        mutate: editEncounterImpRequest, isLoading: isLoadingEncounterImp
    } = usePutQuery({listKeyId: KEYS.encounterImp})

    const editEncounterImp = ({data: formData}) => {
        const {...rest} = formData;
        editEncounterImpRequest({
            url: `${URLS.encounterImp}/${id}`,
            attributes: {
                ...rest,
                patient: {id: patient_id},
            }
        }, {
            onSuccess: () => {
                navigate('/encounters/imp')
            }
        })
    }
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-3">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={`/patient/view/${id}`}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12 mb-5">
                    <Title>{t("Поступление в стационар")} <span
                        className={'text-2xl'}> ({get(data, 'data.payload.encounter.patient.display')}) </span></Title>
                </div>
                <div className="col-span-12">
                    {isLoadingEncounterImp && <ContentLoader/>}
                    <Content sm>
                        <Form defaultValues={{...get(data, 'data.payload.encounter', {})}}
                              classNames={'grid grid-cols-12 gap-x-6'}
                              formRequest={(data) => editEncounterImp(data)}
                              footer={<div className="col-span-12 text-right">
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center mt-7'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>}>
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.bedType}
                                   url={URLS.bedType}
                                   classNames={'col-span-4'} name={`bed_type`}
                                   label={t('Bed Type')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.bed_type')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.encounterPriority}
                                   url={URLS.encounterPriority}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`priority`}
                                   label={t('Priority')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.priority')}
                            />
                            <Field type={FIELDS.treeSelect}
                                   key={KEYS.icd10}
                                   params={{required: true}}
                                   urlSearchParam={{name:get(data, 'data.payload.encounter.admission.admission_diagnosis.code.display')}}
                                   url={URLS.icd10}
                                   classNames={'col-span-4'} name={`admission_diagnosis.code.id`}
                                   label={t('Диагноз при поступлении')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.admission_diagnosis.code.id')}
                            />
                            <Field type={FIELDS.input}
                                   classNames={'col-span-8 mb-8'} name={`admission_diagnosis.note`}
                                   label={t('Diagnose note')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.admission_diagnosis.note')}
                            />
                            <Field
                                dateFormat={"yyyy-MM-dd"}
                                type={FIELDS.datepicker}
                                classNames={'col-span-4'}
                                name={'admission_date'}
                                params={{
                                    required: true,
                                }}
                                property={{
                                    maxDate: dayjs().toDate()
                                }}
                                label={t('Дата приёма')}
                                defaultValue={get(data, 'data.payload.encounter.admission.admission_date')}
                            />
                            <Field type={FIELDS.switch}
                                   classNames={'col-span-4 '} name={`by_ambulance`}
                                   label={t('By Ambulance')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.by_ambulance')}
                            />
                            <Field type={FIELDS.switch}
                                   classNames={'col-span-4 '} name={`repeated_within_30d`}
                                   label={t('Repated within 30 days')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.repeated_within_30d')}
                            />
                            <Field type={FIELDS.switch}
                                   classNames={'col-span-4 '} name={`emergency`}
                                   label={t('Emergency')}
                                   defaultValue={get(data, 'data.payload.encounter.admission.emergency')}
                            />


                            <br/>
                            <DiagnosesForm isCreate/>
                            <br/>
                            <ProcedureForm encounter_id={id}/>
                            <br/>

                            <Panel style={{overflow: 'unset'}}
                                   header={<Title sm>{t("Tuberculosis drug sensitivity")}</Title>} shaded bordered
                                   className={'col-span-12'}>
                                <Field type={FIELDS.switch}
                                       classNames={'col-span-3'} name={`drug_sensitivity.mono_resistant`}
                                       label={t('Mono resistant')}
                                />
                                <Field type={FIELDS.switch}
                                       classNames={'col-span-3'} name={`drug_sensitivity.poly_resistant`}
                                       label={t('Poly resistant')}
                                />
                                <Field type={FIELDS.switch}
                                       classNames={'col-span-3'} name={`drug_sensitivity.multi_resistant`}
                                       label={t('Multi resistant')}
                                />
                                <Field type={FIELDS.switch}
                                       classNames={'col-span-3'} name={`drug_sensitivity.extremely_resistant`}
                                       label={t('Extremely resistant')}
                                />
                                <Field type={FIELDS.switch}
                                       classNames={'col-span-3'} name={`drug_sensitivity.sensitive`}
                                       label={t('Sensitive')}
                                />
                            </Panel>
                            <br/>
                            <ObservationForm encounter_id={id}/>
                            <br/>
                            <NewbornForm/>
                            <br/>
                            <Panel style={{overflow: 'unset'}} header={
                                <Title sm>{t("Sick Leave")}</Title>} shaded bordered
                                   className={'col-span-12'}>
                                <div className="grid grid-cols-12 gap-x-6">
                                    <Field
                                        dateFormat={"yyyy-MM-dd"}
                                        type={FIELDS.datepicker}
                                        classNames={'col-span-4'}
                                        name={'sick_leave.start_date'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('Start date')}
                                    />
                                    <Field
                                        dateFormat={"yyyy-MM-dd"}
                                        type={FIELDS.datepicker}
                                        classNames={'col-span-4'}
                                        name={'sick_leave.end_date'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('End date')}
                                    />
                                    <Field
                                        type={FIELDS.input}
                                        classNames={'col-span-4'}
                                        name={'sick_leave.comments'}
                                        label={t('Comment')}
                                    />
                                </div>
                            </Panel>
                            <br/>
                            <Panel style={{overflow: 'unset'}} header={<Title sm>{t("Responsible persons")}</Title>}
                                   shaded bordered
                                   className={'col-span-12'}>
                                <div className="grid grid-cols-12 gap-x-6">
                                    <Field params={{
                                        required: true,
                                    }} classNames={'col-span-4'} type={FIELDS.asyncSelect}
                                           dataKey={'payload.data'}
                                           name={'treatment.attender'}
                                           label={t('Attender')}
                                           url={URLS.practitionerRoleList}
                                           keyId={KEYS.practitionerRoleList}/>
                                    <Field params={{
                                        required: true,
                                    }} classNames={'col-span-4'} type={FIELDS.asyncSelect}
                                           dataKey={'payload.data'}
                                           name={'treatment.responsible'}
                                           label={t('Responsible')}
                                           url={URLS.practitionerRoleList}
                                           keyId={KEYS.practitionerRoleList}/>
                                </div>
                            </Panel>
                        </Form>

                    </Content>
                </div>
            </div>
        </>
    )
        ;
}

export default Patient066UpdateContainer;
