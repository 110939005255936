import React from 'react';
import Brand from "../brand";
import Wrapper from "../wrapper";
import searchIcon from "../../assets/icons/search.svg"
import Menu from "../menu";
import Language from "../language";
import Notifications from "../notifications";
import Profile from "../profile";

const Index = () => {

    return (
        <header className={' bg-white py-3.5'}>
            <Wrapper>
                <div className="grid grid-cols-12 items-center">
                    <div className="col-span-2">
                        <Brand/>
                    </div>
                    <div className="col-span-7">
                        <Menu/>
                    </div>
                    <div className="col-span-3 text-right flex justify-end items-center">
                        <Language/>
                        <div className={'cursor-pointer mr-8'}>
                            <img src={searchIcon} alt=""/>
                        </div>
                        <Notifications/>
                        <Profile />
                    </div>
                </div>
            </Wrapper>
        </header>
    );
};

export default Index;
