import React from 'react';
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    return (
        <div>
            {t("403 Forbidden Page")}
        </div>
    );
};

export default Index;