import React from 'react';
import BudgetInsertContainer from "../containers/BudgetInsertContainer";

const BudgetInsertPage = () => {
    return (
        <>
            <BudgetInsertContainer/>
        </>
    );
};

export default BudgetInsertPage;