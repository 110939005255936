import React, {useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import Badge from "../../../components/badge";
import {get} from "lodash"
import Input from "../../../components/input";
import {Link, useNavigate} from "react-router-dom";
import {Tab, Tabs} from "../../../components/tab";
import {split} from "lodash/string";
import {FIELDS, REPORT_TYPE_STATUS} from "../../../constants";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {Eye} from "react-feather";


const ChairmanListContainer = () => {
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [filter, setFilter] = useState({})
    const {t} = useTranslation()
    const navigate = useNavigate()

    const columns = [
        {
            title: t('Ф.И.О'),
            key: 'patient',
            render: ({value}) => <Link to={`/patient/view/${get(value,'id')}`}>{get(value, 'last_name')} {get(value, 'first_name')} {get(value, 'middle_name')}</Link>
        },
        {
            title: t('ПИНФЛ'),
            key: 'patient.pin',
        },
        {
            title: t('Врач'),
            key: 'requester.display',
        },
        {
            title: t('Дата выписки'),
            key: 'created_at',
            render: ({value}) => dayjs(value).format("DD.MM.YYYY")
        },
        {
            title: t('№ рецепт'),
            key: 'rx_code',
        },
        {
            title: t('СТАТУС'),
            key: 'status.display',
            render: ({value, row}) => <Badge status={get(row, 'status.code')}>{value}</Badge>
        },
    ]
    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-12">
                    <div className="flex justify-between items-center">
                        <Title className={'mr-5'}>{t("Рецепт")}</Title>
                    </div>
                </div>
                <div className="col-span-12 mt-8">
                    <Tabs hasActiveBg>
                        <Tab tab={'active'} label={t('Действующий')}>
                            <GridView
                                isContentLoader
                                filters={<div className={' py-4 px-2 '}>
                                    <Form resetFields={{
                                        name: '',
                                        organization_id: null,
                                        status_id: null,
                                        requester_id: null,
                                        dispenser_id: null,
                                        rx_code: '',
                                        date_from: null,
                                        date_to: null,
                                        state_id: null,
                                        district_id: null,
                                        city_id: null
                                    }}
                                          defaultValues={{...filter}}
                                          classNames={'grid grid-cols-12 gap-x-4 items-center'}
                                          formRequest={({data}) => {
                                              const {
                                                  name,
                                                  status_id,
                                                  requester_id,
                                                  dispenser_id,
                                                  rx_code,
                                                  date_from,
                                                  date_to,
                                                  state_id,
                                                  district_id,
                                                  city_id
                                              } = data;
                                              setFilter({
                                                  name,
                                                  status_id: get(status_id, 'id'),
                                                  requester_id: get(requester_id, 'id'),
                                                  dispenser_id: get(dispenser_id, 'id'),
                                                  rx_code: rx_code,
                                                  date_from: date_from,
                                                  date_to: date_to,
                                                  state_id: get(state_id, 'id'),
                                                  district_id: get(district_id, 'id'),
                                                  city_id: get(city_id, 'id'),
                                              })
                                          }
                                          }>
                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                               placeholder={t('Ф.И.О')}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} name={'status_id'}
                                               placeholder={t('Status')} url={URLS.medicationrequestStatusV1}
                                               keyId={KEYS.medicationrequestStatusV1}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'organization_id'}
                                               placeholder={t('Organization')} url={URLS.organizations}
                                               keyId={KEYS.organizations}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'requester_id'}
                                               placeholder={t('Requester')} url={URLS.practitionerRoleList}
                                               keyId={KEYS.practitionerRoleList}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'dispenser_id'}
                                               placeholder={t('Dispenser')} url={URLS.practitionerRoleList}
                                               keyId={KEYS.practitionerRoleList}/>

                                        <Field defaultValue={null} isClearable classNames={'col-span-3'}
                                               type={FIELDS.datepicker} name={'date_from'}
                                               placeholder={t('Date from')}/>
                                        <Field defaultValue={null} isClearable classNames={'col-span-3'}
                                               type={FIELDS.datepicker} name={'date_to'} placeholder={t('Date to')}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'rx_code'}
                                               placeholder={t('Номер рецепта')}/>
                                        <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'state_id'}
                                               placeholder={t('State')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <Field property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                               enabled={!!(regionId)}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'city_id'}
                                               placeholder={t('City')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'district_id'}
                                               placeholder={t('District')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <div className={'col-span-12 my-3'}>
                                            <div className="flex items-center">
                                                <button type={'submit'}
                                                        className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                                    {t('Фильтр')}
                                                </button>
                                                <button onClick={() => setFilter({})} type={'reset'}
                                                        className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                                    {t('Очистить фильтр')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>}
                                hasActionColumn={false}
                                listKey={KEYS.medicationRequest}
                                url={URLS.medicationRequest}
                                params={{
                                    ...filter,
                                }}
                                columns={columns}
                                dataKey={'data.payload.data'}
                                rowKey={'key'}
                            />
                        </Tab>
                        <Tab tab={'group'} label={t('На подтверждение')}>
                            <GridView
                                polling={10000}
                                filters={<div className={'flex py-4 px-2 items-center justify-between'}>
                                    <div className={'flex items-center'}>
                                        <Input value={get(filter, 'patient_name')}
                                               setValue={(val) => setFilter(prev => ({...prev, patient_name: val}))}
                                               placeholder={t('Поиск  Ф.И.О.')}/>
                                        <Input classNames={'ml-6'} value={get(filter, 'pin')}
                                               setValue={(val) => setFilter(prev => ({...prev, pin: val}))}
                                               placeholder={t('Поиск  ПИНФЛ')}/>
                                        <Input classNames={'ml-6'} value={get(filter, 'requester_name')}
                                               setValue={(val) => setFilter(prev => ({...prev, requester_name: val}))}
                                               placeholder={t('Поиск  Врач')}/>

                                    </div>
                                </div>}
                                hasActionColumn={false}
                                listKey={KEYS.medicationRequestGroup}
                                url={URLS.medicationRequestGroup}
                                params={{
                                    ...filter,
                                    status_code: REPORT_TYPE_STATUS.review
                                }}
                                columns={[
                                    {
                                        title: t('Ф.И.О'),
                                        key: 'patient',
                                        render: ({value}) => <Link to={`/patient/view/${get(value,'id')}`}>{get(value, 'last_name')} {get(value, 'first_name')} {get(value, 'middle_name')}</Link>
                                    },
                                    {
                                        title: t('ПИНФЛ'),
                                        key: 'patient.pin',
                                    },
                                    {
                                        title: t('Врач'),
                                        key: 'requester.display',
                                    },
                                    {
                                        title: t('Дата выписки'),
                                        key: 'created_at',
                                        render: ({value}) => dayjs(value).format("DD.MM.YYYY")
                                    },
                                    {
                                        title: t('Actions'),
                                        key: 'created_at',
                                        render: ({row:val}) => <Eye
                                            onClick={() => navigate(`/chairman/medication-request/${get(val, 'medication_requests[0].patient.id')}/${get(val, 'id')}`)}
                                            className={'mx-auto '} size={21}/>
                                    }
                                ]}
                                dataKey={'data.payload.data'}
                                rowKey={'key'}
                                // onRowClick={(val) => navigate(`/chairman/medication-request/${get(val, 'medication_requests[0].patient.id')}/${get(val, 'id')}`)}
                            />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div>
    );
};

export default ChairmanListContainer;
