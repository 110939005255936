import React from 'react';
import clsx from "clsx";
import {REPORT_TYPE_STATUS} from "../../constants";
import {includes} from "lodash";

const Index = ({children, status = 'success'}) => {
    return (
        <span className={clsx('py-1 px-3 rounded-2xl font-semibold bg-[#FEF5ED] text-[#F2994A] whitespace-nowrap', {
            '!bg-[#EAF7F0] !text-[#27AE60]': includes([REPORT_TYPE_STATUS.success || REPORT_TYPE_STATUS.active], status),
            '!bg-red-100 !text-[#EB5757]': includes([REPORT_TYPE_STATUS.failed, REPORT_TYPE_STATUS.error], status),
            '!bg-[#FCE7D4] !text-[#F2994A]': includes([REPORT_TYPE_STATUS.review], status),
            '!bg-[#ECECEC] !text-black': includes([REPORT_TYPE_STATUS.draft], status)
        })}>
            {children}
        </span>
    );
};

export default Index;