import React from 'react';
import Patient066ListContainer from "../containers/Patient066ListContainer";

const Patient066ListPage = () => {
    return (
        <>
            <Patient066ListContainer />
        </>
    );
};

export default Patient066ListPage;
