import React from 'react';
import {useParams} from "react-router-dom"
import PractitionerContainer from "../containers/PractitionerContainer";

const PractitionerPage = () => {
    const {id} = useParams()
    return (
        <>
            <PractitionerContainer id={id}/>
        </>
    );
};

export default PractitionerPage;