export const URLS = {
    oauth: '/oauth/authorize',
    token: '/oauth/token',
    getMe: '/me',
    login: '/auth/login',
    locales: '/locales',
    translations: '/translations',
    translationsKey: '/translations',
    organizations: '/organizations',
    organizationsListForSelect: '/organizations/list',
    organizationTypeLevel: '/reference-books/organization-type-level',
    organizationTypeMedical: '/reference-books/organization-type-medical',
    organizationType: '/reference-books/organization-type',
    organizationLegalForm: '/reference-books/legal-form',
    organizationTypeService: '/reference-books/organization-type-service',
    organizationManagementForm: '/reference-books/management-form',
    organizationCountry: '/reference-books/country',
    organizationTerritory: '/reference-books/administrative-territory',
    organizationDepartments: '/departments',
    organizationPositions: '/positions',
    practitionerRole: '/reference-books/practitioner-role',
    practitioners: '/practitioners',
    persons: '/persons',
    patients: '/patients',
    roles: '/roles',
    nationality: '/reference-books/nationality',
    citizenship: '/reference-books/country',
    gender: '/reference-books/administrative-gender',
    identifierType: '/reference-books/identifier-type',
    addressUse: '/reference-books/address-use',
    episodeOfCares: '/episode-of-cares',
    conditionSeverity: '/reference-books/condition-severity',
    icd10: '/reference-books/icd-10',
    episodeOfCareStatus: '/reference-books/episode-of-care-status',
    encounterClass: '/reference-books/encounter-class',
    encounterLocationStatus: '/reference-books/encounter-location-status',
    serviceType: '/reference-books/service-type',
    medicationRequestCategory: '/reference-books/medicationrequest-category',
    medicationInn: '/reference-books/medication-inn',
    ucumCommon: '/reference-books/ucum-common',
    medicationFormCode: '/reference-books/medication-form-code',
    medicationDosageMethod: '/reference-books/medication-dosage-method',
    encounters: '/encounters',
    medicationRequest: '/medication-requests',
    medicationConcept: '/medication-concepts',
    medicationDoseUnit: '/reference-books/medication-dose-unit',
    medicationQuantityUnit: '/reference-books/medication-quantity-unit',
    medicationrequestType: '/reference-books/medicationrequest-type',
    routeCode: '/reference-books/route-code',
    timingAbbreviation: '/reference-books/timing-abbreviation',
    medicationDosageUnit: '/reference-books/medication-dosage-unit',
    episodeOfCaresStatusReason: '/reference-books/episode-of-care-status-reason',
    episodeOfCaresDischargeReason: '/reference-books/episode-of-care-discharge-reason',
    medicationVirtualProducts: '/medication-virtual-products',
    medicationrequestStatusV1: '/reference-books/medicationrequest-status-v1',
    medicationRequestGroup: '/medication-requests/group',
    medicationDictionaryList: '/medication-dictionaries/list',
    medicationBrands: '/medication-brands',
    medicationDispense: '/medication-dispenses',
    medicationDispenseUpsert: '/medication-dispenses/upsert',
    budget: '/budgets',
    statistics: '/statistics/budget/state',
    statisticsByPeriod: '/statistics/budget/by-period',
    statisticsRealization: '/statistics/medication-request/realization',
    notifications: '/notifications',
    exportExcel: '/export',
    budgetStates: '/budgets/by-states',
    getBalanceByStates: '/budgets/balance/by-states',
    medicationRequestByGroup: '/medication-requests/group',
    medicationRequestByGroups: '/medication-request-groups',
    bedType: '/reference-books/bed-type',
    encounterPriority: '/reference-books/encounter-priority',
    encounterImp: '/encounters/imp',
    conditionVerStatus: '/reference-books/condition-ver-status',
    conditionClinical: '/reference-books/condition-clinical',
    searchDispensable: '/medication-requests/group/dispensable',
    medicationRequestStatusReason: '/reference-books/medicationrequest-status-reason',
    practitionerRoleList: '/practitioner-roles/list',
    diagnosesRubric: '/reference-books/diagnosis-rubric',
    coverageType: '/reference-books/coverage-type',
    dischargeDisposition: '/reference-books/encounter-discharge-disposition',
    contactRelationship: '/reference-books/patient-contactrelationship',
    concepts: '/concepts/list',
    observations: '/observations',
    proceduresUpsert: '/procedures/upsert',
    observationCategory: '/reference-books/observation-category',
    encounterStatus: '/reference-books/encounter-status',
    newborns: '/newborns',
    observationsUpsert: '/observations/upsert',
    notificationsBatchRead: '/notifications/batch-read',
    notificationsReadAll: '/notifications/read-all',
    notificationsAckAll: '/notifications/ack-all',
    budgetByOrganization: '/budgets/by-organization',
    getBudgetByOrganization: '/budgets/by-organizations',
}
