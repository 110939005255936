import React from 'react';
import RSelect, {components} from 'react-select';
import clsx from "clsx";
import arrowIcon from "../../assets/icons/select-arrow.svg"

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <img src={arrowIcon} alt={'arrow'}/>
            </components.DropdownIndicator>
        )
    );
};
const customStyles = (sm = false,md=false, lg = false) => ({
    container: (base) => ({
      width: lg ? '100%' : 'fit-content'
    }),
    control: (base, state, error) => ({
        ...base,
        background: "#fff",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: md ? '0' : '8px',
        outline: "none",
        color: "#222",
        display: "flex",
        overflow: 'hidden',
        padding: '2px 8px',
        width: sm ? '100px' : md ? '125px' : lg ? '100%' : '225px' ,
        height: md ?'42px':'40px',
        fontSize: '16px',
        fontWeight: '600',
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#B3B3B3',
            fontWeight:400
        }
    },
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    })
});
const Select = ({
                    name = '',
                    value = null,
                    sm = false,
                    md = false,
                    label = '',
                    options = [],
                    setValue = () => {
                    },
                    isClearable = true,
                    isLoading = false,
                    isSearchable=true,
                    defaultValue=null,
                    placeholder='Не выбран',
                  className= '',
                  fullWidth = false
                }) => {

    return (
        <div className={clsx(`flex items-center z-40 relative ${className || ''}`)}>
            {label && <label className={clsx('mr-2 text-secondary-300 font-bold flex items-center ')}
                             htmlFor={label}>{label}</label>}
            <RSelect
                defaultValue={defaultValue}
                isSearchable={isSearchable}
                isLoading={isLoading}
                isClearable={isClearable}
                name={name}
                clearIndicator={true}
                styles={customStyles(sm,md, fullWidth)}
                id={label}
                value={value}
                onChange={setValue}
                options={options}
                components={{DropdownIndicator}}
                placeholder={(sm || md) ? '' : placeholder}
            />
        </div>
    );
};

export default Select;
