import React from 'react';
import PatientsContainer from "../containers/PatientsContainer";

const PatientsPage = () => {
    return (
        <>
            <PatientsContainer/>
        </>
    );
};

export default PatientsPage;