import React from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ChevronLeft} from "react-feather";
import {useGetAllQuery, usePostQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {OverlayLoader} from "../../../components/loader";
import {get, includes} from "lodash";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {Tab, Tabs} from "../../../components/tab";
import Field from "../../../containers/form/field";
import {FIELDS} from "../../../constants";
import Form from "../../../containers/form";


const PractitionerContainer = ({id = null}) => {
    const {t} = useTranslation();
    const {data, isLoading, refetch} = useGetAllQuery({
        key: [KEYS.practitioners, id],
        url: `${URLS.practitioners}/${id}`
    })
    const {data: roles,isLoading:isLoadingRoleList} = useGetAllQuery({key: KEYS.roles, url: URLS.roles})
    const {mutate, isLoading: isLoadingRole} = usePostQuery({listKeyId: [KEYS.practitioners, id]})

    const setRole = ({data:attrs}) => {
        const {roles:roleList=[]}=attrs;
        mutate({
            url: `/practitioners/${id}/user`,
            attributes: {
                roles: get(roles, 'data.payload.data', [])?.filter((_role,index)=> _role && roleList[index]).map(({id})=>id) || []
            }
        }, {
            onSuccess: () => {
                refetch()
            }
        })
    }

    if (isLoading || isLoadingRoleList) {
        return <OverlayLoader/>
    }

    return (<div>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/hrm/practitioners'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12 mb-3">
                    <Title>{get(data, 'data.payload.practitioner.display')}</Title>
                </div>
                <div className="col-span-12">
                    <Tabs hasActiveBg>
                        <Tab tab={'info'} label={t('Personal info')}>
                            <Content sm>
                                <div className="grid grid-cols-12 gap-x-16">
                                    <div className="col-span-12">
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("ПИНФЛ")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.pin')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Пол")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.gender.display')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Birthdate")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.birth_date')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Телефон")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.telecoms[0].value')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Nationality")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.citizenship.display')}</strong>
                                        </div>
                                        <div className={'flex py-5 border-b items-center'}>
                                            <span className={'w-1/3'}>{t("Address")}:</span>
                                            <strong
                                                className={'w-2/3'}>{get(data, 'data.payload.practitioner.addresses[0].line')}</strong>
                                        </div>
                                    </div>
                                </div>
                            </Content>
                        </Tab>
                        <Tab tab={'role'} label={t('Roles')}>
                            <Content sm>
                                {isLoadingRole && <OverlayLoader/>}
                                <div className="grid grid-cols-12 gap-x-16">
                                    <div className="col-span-12">
                                        <Form  classNames={'grid grid-cols-12 gap-x-6'}
                                              formRequest={(data) => setRole(data)}
                                              footer={<div className="col-span-12 text-right">
                                                  <button type={'submit'}
                                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center mt-7'}>
                                                      {t('Сохранить')}
                                                  </button>
                                              </div>}>

                                            {
                                                get(roles, 'data.payload.data', []).map((role, index) => <Field
                                                    type={FIELDS.switch}
                                                    defaultValue={includes(get(data,'data.payload.practitioner.user.roles',[]),get(role, 'code'))}
                                                    classNames={'col-span-4 mb-5'}
                                                    name={`roles[${index}]`}
                                                    label={get(role, 'display')}
                                                />)
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </Content>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default PractitionerContainer;