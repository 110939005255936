import React from 'react';
import orgIcon from "../../assets/icons/org.svg"
import orgRedBOrderIcon from "../../assets/icons/org-red-border.svg"

const Index = ({children='',isError=false,isRequired=false}) => {
    return (
        <label className={'form-label flex'}>{children}
            {
                isRequired ? <img  className={'ml-1'} src={isError ? orgRedBOrderIcon : orgIcon} alt="icon" /> : " "
            }
        </label>
    );
};

export default Index;