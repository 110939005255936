import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ChevronLeft, Plus} from "react-feather";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {useGetOneQuery, usePostQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import patientImg from "../../../assets/images/patient.png";
import {find, get, isEqual} from "lodash";
import phoneIcon from "../../../assets/icons/phone.svg";
import emailIcon from "../../../assets/icons/mail.svg";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import GridView from "../../../containers/grid-view";
import dayjs from "dayjs";
import Modal from "../../../components/modal";
import {FIELDS} from "../../../constants";

const PatientEncounterContainer = ({id}) => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.patients, key: [KEYS.patients, id]})
    const {
        mutate: createEncounterRequest, isLoading: isLoadingPost
    } = usePostQuery({listKeyId: KEYS.encounterClass})
    const createEncounter = ({data: attrs}) => {
        createEncounterRequest({url: URLS.encounters, attributes: {...attrs, patient: {id: id}}}, {
            onSuccess: () => {
                setOpen(false)
            }
        })
    }
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <button onClick={() => navigate(-1)} className={'text-primary  font-bold inline-flex items-center'}
                    ><ChevronLeft className={'mr-1'}/>{t("Назад к пациент")}</button>
                </div>
                <div className="col-span-12">
                    <Content sm>
                        <Title className={'mb-6'}>{t("Амбулаторный прием 025 форма")}</Title>
                        <div className="grid grid-cols-12 gap-x-10">
                            <div className="col-span-6">
                                <div
                                    className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                    <div className={'flex items-center'}>
                                        <img src={patientImg} alt=""/>
                                        <h2 className={'text-lg ml-5 font-bold'}>{get(data, 'data.payload.patient.last_name')} {get(data, 'data.payload.patient.first_name')} {get(data, 'data.payload.patient.middle_name')}</h2>
                                    </div>
                                    <div className="grid grid-cols-12 mt-5">
                                        <div className="col-span-7">
                                            <ul>
                                                <li className={'grid-cols-12 grid'}>
                                                    <strong className={'col-span-6'}>Пол:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{get(data, 'data.payload.patient.gender.display')}</span>
                                                </li>
                                                <li className={'grid-cols-12 grid mt-4'}>
                                                    <strong className={'col-span-6'}>Возраст:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{dayjs().format("YYYY") - dayjs(get(data, 'data.payload.patient.birth_date')).format("YYYY")}</span>
                                                </li>
                                                <li className={'grid-cols-12 grid mt-4'}>
                                                    <strong className={'col-span-6'}>Дата рождения:</strong>
                                                    <span
                                                        className={'col-span-6 text-[#808080]'}>{get(data, 'data.payload.patient.birth_date')}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-span-5">
                                            <ul>
                                                {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value') &&
                                                    <li className={'flex items-center '}>
                                                        <img className={'flex-none'} src={phoneIcon} alt=""/>
                                                        <span
                                                            className={'ml-4'}>+{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value')}</span>
                                                    </li>}
                                                {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value') &&
                                                    <li className={'flex items-center mt-4'}>
                                                        <img className={'flex-none'} src={emailIcon} alt=""/>
                                                        <span
                                                            className={'ml-4'}>{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value')}</span>
                                                    </li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 flex items-center justify-end">
                            <button
                                onClick={() => setOpen(true)}
                                className={'inline-flex py-2.5 pl-2.5 pr-5 rounded-lg bg-primary items-center text-white font-semibold text-center '}>
                                <Plus className={'mr-1.5'}/>
                                {t('Амбулаторный прием 025 форма')}
                            </button>
                        </div>
                        <div className="col-span-12 mt-3">
                            <div
                                className={"rounded-xl shadow-xl  border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                <GridView
                                    bordered
                                    dataKey={'data.payload.data'}
                                    metaDataKey={'data.payload.meta'}
                                    params={{
                                        patient_id: id
                                    }}
                                    hasActionColumn={true}
                                    listKey={KEYS.encounters}
                                    url={URLS.encounters}
                                    columns={[
                                        {
                                            title: t('№'),
                                            key: 'id',
                                            render: ({number}) => number,
                                        },
                                        {
                                            title: t('Encounter class'),
                                            key: 'class.display',
                                        },
                                        {
                                            title: t('Episode of care'),
                                            key: 'episode_of_care.id',
                                        },
                                        {
                                            title: t('Service type'),
                                            key: 'service_types',
                                            render: ({value}) => value?.map(({display}) => display).join(', ')
                                        },
                                        {
                                            title: t('Дата начала'),
                                            key: 'period_start',
                                            render: ({value}) => dayjs(value).format('DD.MM.YYYY HH:mm')
                                        },
                                    ]}
                                    modalClassNames={'!w-[1080px]'}
                                    ModalBody={(onSubmit, defaultValues = {}, setRowId) => <Form
                                        classNames={'grid grid-cols-12 gap-x-6'} formRequest={onSubmit}
                                        defaultValues={{
                                            ...get(defaultValues, 'data.payload.encounter', {}),
                                        }} footer={<div className={'col-span-12 '}>
                                        <div className="flex justify-end">
                                            <button onClick={() => setRowId(null)} type={'button'}
                                                    className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                                {t('Отмена')}
                                            </button>
                                            <button type={'submit'}
                                                    className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                                {t('Сохранить')}
                                            </button>
                                        </div>
                                    </div>}>
                                        <Field
                                            type={FIELDS.asyncSelect}
                                            url={URLS.encounterClass}
                                            keyId={KEYS.encounterClass}
                                            classNames={'col-span-6'}
                                            name={'class'}
                                            params={{
                                                required: true,
                                            }}
                                            label={t('Encounter class')}
                                        />
                                        <Field
                                            property={{optionLabel: 'id', optionValue: 'id'}}
                                            dataKey={'payload.data'}
                                            type={FIELDS.asyncSelect}
                                            url={URLS.episodeOfCares}
                                            keyId={KEYS.episodeOfCares}
                                            classNames={'col-span-6'}
                                            name={'episode_of_care'}
                                            label={t('Episode of care')}
                                        />
                                        <Field
                                            isMulti
                                            type={FIELDS.asyncSelect}
                                            url={URLS.serviceType}
                                            keyId={KEYS.serviceType}
                                            classNames={'col-span-12'}
                                            name={'service_types'}
                                            params={{
                                                required: true,
                                            }}
                                            label={t('Service type')}
                                        />
                                        <Field
                                            dateFormat={"yyyy-MM-dd"}
                                            type={FIELDS.datepicker}
                                            classNames={'col-span-3'}
                                            name={'period_start'}
                                            params={{
                                                required: true,
                                            }}
                                            label={t('Дата начала')}
                                        />
                                        <Field
                                            type={FIELDS.asyncSelect}
                                            url={URLS.encounterLocationStatus}
                                            keyId={KEYS.encounterLocationStatus}
                                            classNames={'col-span-9'}
                                            name={'location'}
                                            label={t('Location status')}
                                        />
                                    </Form>}
                                />
                            </div>
                        </div>
                        <Modal open={open} onClose={() => setOpen(false)} classNames={'!w-[1080px] !pb-0'}
                               title={t('Амбулаторный прием 025 форма')}>
                            {isLoadingPost && <ContentLoader/>}
                            <div
                                className={"pb-6"}>
                                <Form classNames={'grid grid-cols-12 gap-x-6'} formRequest={createEncounter}
                                      footer={<div className={'col-span-12 '}>
                                          <div className="flex justify-end">
                                              <button onClick={() => setOpen(false)} type={'button'}
                                                      className={'text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg mr-4 inline-block   font-bold text-center  mt-6'}>
                                                  {t('Отмена')}
                                              </button>
                                              <button type={'submit'}
                                                      className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                                  {t('Сохранить')}
                                              </button>
                                          </div>
                                      </div>}>
                                    <Field
                                        type={FIELDS.asyncSelect}
                                        url={URLS.encounterClass}
                                        keyId={KEYS.encounterClass}
                                        classNames={'col-span-6'}
                                        name={'class'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('Encounter class')}
                                    />
                                    <Field
                                        property={{optionLabel: 'id', optionValue: 'id'}}
                                        dataKey={'payload.data'}
                                        type={FIELDS.asyncSelect}
                                        url={URLS.episodeOfCares}
                                        keyId={KEYS.episodeOfCares}
                                        classNames={'col-span-6'}
                                        name={'episode_of_care'}
                                        label={t('Episode of care')}
                                    />
                                    <Field
                                        isMulti
                                        type={FIELDS.asyncSelect}
                                        url={URLS.serviceType}
                                        keyId={KEYS.serviceType}
                                        classNames={'col-span-12'}
                                        name={'service_types'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('Service type')}
                                    />
                                    <Field
                                        dateFormat={"yyyy-MM-dd"}
                                        type={FIELDS.datepicker}
                                        classNames={'col-span-3'}
                                        name={'period_start'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('Дата начала')}
                                    />
                                    <Field
                                        type={FIELDS.asyncSelect}
                                        url={URLS.encounterLocationStatus}
                                        keyId={KEYS.encounterLocationStatus}
                                        classNames={'col-span-9'}
                                        name={'location'}
                                        label={t('Location status')}
                                    />
                                </Form>
                            </div>
                        </Modal>
                    </Content>
                </div>
            </div>
        </>
    );
}

export default PatientEncounterContainer;