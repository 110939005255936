import React, {useEffect, memo} from 'react';
import clsx from "clsx";
import {Controller, useFormContext} from "react-hook-form";
import {get, hasIn, isFunction} from "lodash";
import {useTranslation} from "react-i18next";
import Label from "../../../components/label";
import TreePicker from "../../../components/select/TreePicker";

const TreeSelect = ({
                        property,
                        name,
                        params,
                        label = '',
                        classNames = '',
                        defaultValue = undefined,
                        url = '',
                        keyId = 'list',
                        isDisabled = false,
                        dataKey = null,
                        itemIsSelectable=false,
                        urlSearchParam={}
                    }) => {
    const {control, watch, getValues, reset, formState: {errors}} = useFormContext()
    const {t} = useTranslation()
    const isError = hasIn(errors, name);

    useEffect(() => {
        if (isFunction(get(property, 'onChange'))) {
            get(property, 'onChange')(getValues(name), name);
        }

    }, [watch(name)]);

    return (
        <div className={clsx(`form-group ${classNames}`)}>
            <Label isError={isError} isRequired={get(params, 'required', false)}>{label ?? name}</Label>
            <Controller
                control={control}
                name={name}
                rules={params}
                defaultValue={defaultValue}
                render={({field}) => {
                    return <TreePicker
                        urlSearchParam={urlSearchParam}
                        classNames={clsx({'tree-error': isError})}
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                        url={url}
                        keyId={keyId}
                        isDisabled={isDisabled}
                        dataKey={dataKey}
                        itemIsSelectable={itemIsSelectable}
                        optionLabel={get(property,'optionLabel') || 'display'}
                    />
                }}
            />
            {get(errors, `${name}.type`) === 'required' &&
            <span className={'form-error'}>{t('Заполните обязательное поле')}</span>}
            {get(errors, `${name}.type`) === 'validation' &&
            <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
        </div>
    );
};

export default memo(TreeSelect);
