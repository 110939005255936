import React from 'react';
import ReportContainer from "../containers/ReportContainer";

const ReportPage = () => {
    return (
        <>
            <ReportContainer />
        </>
    );
};

export default ReportPage;