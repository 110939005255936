import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {useTranslation} from "react-i18next";
import abbreviate from "number-abbreviate";


const CustomBarChart = ({data=[]}) => {
    const {t} = useTranslation()
    const RenderLegend = (value, entry) => {
        const {color} = entry;
        return (
            <div style={{color}}>{t(value)}</div>
        );
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 15,
                    right: 15,
                    left: 30,
                    bottom: 30,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month.display"/>
                <YAxis tickFormatter={abbreviate}/>
                <Tooltip/>
                <Legend verticalAlign="top" formatter={RenderLegend} className={'flex'}/>
                <Bar dataKey="residual_amount" fill="#FFD4F4"/>
                <Bar dataKey="allocated_amount" fill="#D64BC2"/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;