import React from 'react';
import ViewContainer from "../containers/ViewContainer";

const ViewPage = () => {
    return (
        <>
            <ViewContainer />
        </>
    );
};

export default ViewPage;