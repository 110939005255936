import React, {useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {useTranslation} from "react-i18next";
import YearMonthPicker from "../../../components/year-month-picker";
import downloadIcon from "../../../assets/icons/download.svg";
import dayjs from "dayjs";
import Badge from "../../../components/badge";
import {find, get, isEqual, isNil} from "lodash"
import Input from "../../../components/input";
import {Link} from "react-router-dom";
import Select from "../../../components/select";
import {useGetAllQuery, usePutQuery} from "../../../hooks/api";
import {split} from "lodash/string";
import Swal from "sweetalert2";
import {ContentLoader} from "../../../components/loader";
import {EXCEL_EXPORT_TYPE, FIELDS, REPORT_TYPE_STATUS} from "../../../constants";
import {toast} from "react-toastify";
import Button from "../../../components/button";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import Modal from "../../../components/modal";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import orgIcon from "../../../assets/icons/org.svg";
import Names from "../../../components/names";
import Locations from "../../../components/locations";
import Contacts from "../../../components/contacts";
import {Eye} from "react-feather";
import RecipeDetailModal from "../../../components/recipe-detail-modal";


const ListContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [row, setRow] = useState(null)
    const [exportLoading, setExportLoading] = useState(false)
    const [filter, setFilter] = useState({})
    const {t} = useTranslation()

    const {mutate: reviewRequest, isLoading: isLoadingReview} = usePutQuery({listKeyId: KEYS.medicationRequest})
    const reviewMedicationRequest = (_id) => {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: t('Are you sure?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
        }).then((result) => {
            if (result.isConfirmed) {
                reviewRequest({url: `${URLS.medicationRequest}/${_id}/in-review`})
            }
        });
    }
    const {
        refetch
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.MedicationRequest}`,
        cb: {
            success: (res) => {
                setExportLoading(false);
                toast.success(get(res, 'message'))
            },
            fail: () => {
                setExportLoading(false)
            }
        }
    })

    const columns = [
        {
            title: t('Ф.И.О'),
            key: 'patient',
            render: ({value}) => <Link className={'text-blue-500'}
                                       to={`/patient/view/${get(value, 'id')}`}>{`${get(value, 'last_name')} ${get(value, 'first_name')} ${get(value, 'middle_name')}`}</Link>
        },
        {
            title: t('ПИНФЛ'),
            key: 'patient.pin',
        },
        {
            title: t('Врач'),
            key: 'requester.display',
        },
        {
            title: t('Дата выписки'),
            key: 'created_at',
            render: ({value}) => dayjs(value).format("DD.MM.YYYY")
        },
        {
            title: t('№ рецепт'),
            key: 'rx_code',
            render: ({value, row: _row}) => <span>{value}</span>
        },
        {
            title: t('СТАТУС'),
            key: 'status.display',
            render: ({value, row}) => <Badge status={get(row, 'status.code')}>{value}</Badge>
        },
        {
            title: t('Действия'),
            key: 'status.code',
            render: ({
                         value,
                         row
                     }) => <>
                <Eye className={'mr-3.5 inline'}
                     size={22} onClick={() => setRow(row)}/> {isEqual(value, REPORT_TYPE_STATUS.draft) &&
            <AllowedAccess permissions={[config.PERMISSIONS.medication_request_update_status]}>
                <button
                    type={"button"}
                    onClick={(e) => {
                        e.stopPropagation();
                        reviewMedicationRequest(get(row, 'id'));
                    }}
                    className={' py-2.5 px-5  text-white min-w-[150px] rounded-lg  border bg-[#006D85] font-bold text-center  mt-3  items-center '}>
                    {t("Send review")}
                </button>
            </AllowedAccess>}
            </>
        },
    ]
    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-12">
                    <div className="flex justify-between items-center">
                        <Title className={'mr-5'}>{t("Рецепт")}</Title>
                        <div className="flex">
                            <YearMonthPicker getMonth={setMonth} getYear={setYear}/>
                            <AllowedAccess permissions={[config.PERMISSIONS.medication_request_export]}><Button
                                loading={exportLoading} classNames={'ml-6 !pr-5 !text-sm'}
                                icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>} onClick={() => {
                                setExportLoading(true)
                                refetch()
                            }}>
                                {t("Export")}
                            </Button>
                            </AllowedAccess>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-8">
                    {isLoadingReview && <ContentLoader/>}
                    <GridView
                        isContentLoader
                        filters={<div className={' py-4 px-2 '}>
                            <Form resetFields={{
                                name: '',
                                organization_id: null,
                                patient_id: null,
                                status_id: null,
                                requester_id: null,
                                dispenser_id: null,
                                rx_code: '',
                                date_from: null,
                                date_to: null,
                                state_id: null,
                                district_id: null,
                                city_id: null
                            }}
                                  defaultValues={{...filter}} classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                  formRequest={({data}) => {
                                      const {
                                          name,
                                          status_id,
                                          patient_id,
                                          requester_id,
                                          dispenser_id,
                                          rx_code,
                                          date_from,
                                          date_to,
                                          state_id,
                                          district_id,
                                          city_id
                                      } = data;
                                      setFilter({
                                          name,
                                          status_id: get(status_id, 'id'),
                                          patient_id: get(patient_id, 'id'),
                                          requester_id: get(requester_id, 'id'),
                                          dispenser_id: get(dispenser_id, 'id'),
                                          rx_code: rx_code,
                                          date_from: date_from,
                                          date_to: date_to,
                                          state_id: get(state_id, 'id'),
                                          district_id: get(district_id, 'id'),
                                          city_id: get(city_id, 'id'),
                                      })
                                  }
                                  }>
                                <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                       placeholder={t('Ф.И.О')}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} name={'status_id'}
                                       placeholder={t('Status')} url={URLS.medicationrequestStatusV1}
                                       keyId={KEYS.medicationrequestStatusV1}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'organization_id'}
                                       placeholder={t('Organization')} url={URLS.organizations}
                                       keyId={KEYS.organizations}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'requester_id'}
                                       placeholder={t('Requester')} url={URLS.practitionerRoleList}
                                       keyId={KEYS.practitionerRoleList}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'dispenser_id'}
                                       placeholder={t('Dispenser')} url={URLS.practitionerRoleList}
                                       keyId={KEYS.practitionerRoleList}/>

                                <Field classNames={'col-span-3'} type={FIELDS.input} name={'rx_code'}
                                       placeholder={t('Номер рецепта')}/>
                                <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'state_id'}
                                       placeholder={t('State')} url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field property={{onChange: (val) => setCityId(get(val, 'id'))}} enabled={!!(regionId)}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'city_id'}
                                       placeholder={t('City')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'district_id'}
                                       placeholder={t('District')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <div className={'col-span-6 mt-1.5'}>
                                    <div className="flex items-center">
                                        <button type={'submit'}
                                                className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                            {t('Фильтр')}
                                        </button>
                                        <button onClick={() => setFilter({})} type={'reset'}
                                                className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                            {t('Очистить фильтр')}
                                        </button>
                                    </div>
                                </div>
                            </Form>

                        </div>}
                        hasActionColumn={false}
                        listKey={KEYS.medicationRequest}
                        url={URLS.medicationRequest}
                        params={{
                            ...filter,
                            date_from: month && year ? `${year}-${month}-01` : undefined,
                            date_to: month && year ? `${year}-${month}-31` : undefined,
                        }}
                        columns={columns}
                        dataKey={'data.payload.data'}
                        rowKey={'key'}
                    />
                </div>
            </div>
            <Modal open={!isNil(row)} onClose={() => setRow(null)} classNames={'!w-[1000px]'}
                   title={t('Подробности рецепта')}>
                <RecipeDetailModal row={row}/>
            </Modal>

        </div>
    );
};

export default ListContainer;
