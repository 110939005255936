import React, {useState} from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {isEqual, get, uniq, dropRight} from "lodash";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {useTranslation} from "react-i18next";
import {useFieldArray, useFormContext} from "react-hook-form";
import Title from "../title";
import {Panel, Stack} from "rsuite";

const Index = () => {
    const [selected, setSelected] = useState([])
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'diagnoses',
        keyName: 'uuid'
    });
    return (
        <Panel style={{overflow: 'unset'}} header={
            <Stack justifyContent="space-between">
                <Title sm>{t("Диагнозы пациента")}</Title>
                <Button htmlType={'button'} onClick={() => append({rubric: "", code: null})}
                        classNames={'p-1.5'}><Plus size={20}/></Button>
            </Stack>
        } bordered className={'col-span-12'} shaded>

            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12  mb-3 ">
                    <div className={'grid grid-cols-12 gap-x-6 items-start'}>
                        <Field
                            property={{
                                onChange: (val) => {
                                    if (isEqual(get(val,'code'),'MN')) {
                                        setSelected(prev => uniq([...prev, get(val,'id')]))
                                    }
                                },
                                disabledOptions:selected
                            }}
                            isDisabled={isEqual(get(field,'rubric.code'),'MN')}
                            classNames={'col-span-5'} label={t("Рубрика")} type={FIELDS.asyncSelect}
                            params={{required: true}} url={URLS.diagnosesRubric}
                            keyId={KEYS.diagnosesRubric} name={`diagnoses[${index}].rubric`}/>
                        <Field type={FIELDS.treeSelect}
                               key={KEYS.icd10}
                               defaultValue={get(field, 'code.id')}
                               urlSearchParam={{name:get(field, 'code.display')}}
                               url={URLS.icd10}
                               params={{required: true}}
                               classNames={'col-span-6'} name={`diagnoses[${index}].code.id`}
                               label={t('Диагноз')}
                        />
                        {!isEqual(index, 0) && <div className="col-span-1 flex justify-end mt-6">
                            <Button htmlType={'button'} onClick={() => {
                                remove(index);
                                setSelected(prev=>dropRight(prev))
                            }}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
        </Panel>
    );
};

export default Index;
