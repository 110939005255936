import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import GridView from "../../../containers/grid-view";
import {URLS} from "../../../constants/urls";
import {KEYS} from "../../../constants/keys";
import Content from "../../../components/content";
import YearMonthPicker from "../../../components/year-month-picker";
import {NavLink} from "react-router-dom";
import {NumericFormat} from "react-number-format";

const BudgetContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const {t} = useTranslation()
    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 mb-5">
                <Title>{t("О бюджете")}</Title>
            </div>
            <div className={'col-span-12'}>
                <Content classNames={'!bg-white'}>
                    <div className="grid grid-cols-12 gap-x-8">
                        <div className="col-span-3">
                            <div
                                className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                <NavLink
                                    className={'block border border-[#47748A] rounded-lg text-center p-2.5 mb-3 font-semibold  bg-[#47748A] text-white'}
                                    to={'/budget'}>
                                    {t("Информация о бюджете")}
                                </NavLink>
                                <NavLink
                                    className={'block border border-[#E5E5E5] rounded-lg text-center p-2.5 mb-3 font-semibold '}
                                    to={'/budget/insert'}>
                                    {t("Внесение бюджета")}
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-span-9">
                            <div className={'mb-3'}>
                                <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                            </div>
                            <GridView
                                url={URLS.getBalanceByStates}
                                listKey={KEYS.getBalanceByStates}
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                params={{
                                    fiscal_year: parseInt(year),
                                    month: parseInt(month)
                                }}
                                columns={[
                                    {
                                        title: t('№'),
                                        key: 'id',
                                        render: ({number}) => number,
                                    },
                                    {
                                        title: t('Город'),
                                        key: 'area.display'
                                    },
                                    {
                                        title: t('Бюджет на месяц'),
                                        key: 'allocated_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    },
                                    {
                                        title: t('С прошлого месяца'),
                                        key: 'spent_amount',
                                        render: ({value}) => 0
                                    },
                                    {
                                        title: t('Общая сумма'),
                                        key: 'allocated_amount',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={' '}/>
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default BudgetContainer;