
import React from 'react';
import PatientCreateContainer from "../containers/PatientCreateContainer";

const PatientCreatePage = () => {
    return (
        <>
            <PatientCreateContainer/>
        </>
    );
};

export default PatientCreatePage;