import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Controller, useFormContext} from "react-hook-form";
import {get, hasIn} from "lodash";
import dayjs from "dayjs";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import Label from "../../../components/label";
import ru from "date-fns/locale/ru"



const CustomDatepicker = ({
                              disabled = false,
                              name,
                              params,
                              property,
                              defaultValue = new Date(),
                              label,
                              dateFormat = "yyyy-MM-dd",
                              classNames = "",
                              isClearable=false,
                              placeholder=''
                          }) => {
    const {control, formState: {errors}} = useFormContext()
    const {t} = useTranslation()
    const isError = hasIn(errors, name);
    return (
        <div className={clsx("form-group", classNames)}>
            <Label isError={isError} isRequired={get(params, 'required', false)}>{label}</Label>
            <Controller
                control={control}
                name={name}
                rules={params}
                defaultValue={defaultValue ? defaultValue : null}
                render={({field}) => (<DatePicker
                    locale={ru}
                    calendarStartDay={1}
                    dateFormat={dateFormat}
                    selected={field.value ? dayjs(field.value).toDate() : null}
                    onChange={(date) => field.onChange(date)}
                    readOnly={disabled}
                    showMonthYearPicker={get(property, 'showMonthYearPicker')}
                    className={clsx('form-input pr-10 w-100 flex', {'border-red-600': hasIn(errors, name)})}
                    isClearable={isClearable}
                    placeholderText={placeholder}
                    showTimeInput={get(property, 'showTimePicker')}
                    minDate={get(property, 'minDate')}
                    maxDate={get(property, 'maxDate')}

                />)
                }
            />
            {errors[name]?.type === 'required' &&
                <span className={'text-red-600 text-xs'}>{t('Заполните обязательное поле')}</span>}
            {errors[name]?.type === 'validation' &&
                <span className={'text-red-600 text-xs'}>{get(errors, `${name}.message`)}</span>}
        </div>
    );
};

export default CustomDatepicker;
