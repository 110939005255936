import React from 'react';
import {useParams} from "react-router-dom"
import Patient066UpdateContainer from "../containers/Patient066UpdateContainer";

const Patient066UpdatePage = () => {
    const {patient_id,id} = useParams()
    return (
        <>
            <Patient066UpdateContainer id={id} patient_id={patient_id}/>
        </>
    );
};

export default Patient066UpdatePage;
