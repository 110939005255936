import React from 'react';
import BudgetByOrganizationContainer from "../containers/BudgetByOrganizationContainer";

const BudgetByOrganizationPage = () => {
    return (
        <>
            <BudgetByOrganizationContainer/>
        </>
    );
};

export default BudgetByOrganizationPage;