import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {useGetAllQuery} from "../../../hooks/api";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {get, isEmpty, head, isEqual} from "lodash"
import {OverlayLoader} from "../../../components/loader";
import clsx from "clsx";
import YearMonthPicker from "../../../components/year-month-picker"
import {BarChart} from "../../../components/chart";
import img1 from "../../../assets/icons/budget1.svg"
import img2 from "../../../assets/icons/budget2.svg"
import img3 from "../../../assets/icons/budget3.svg"
import img4 from "../../../assets/icons/budget4.svg"
import {NumericFormat} from "react-number-format";
import GridView from "../../../containers/grid-view";
import {ArrowLeft, SkipBack} from "react-feather";

const StatisticsContainer = () => {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [region, setRegion] = useState(null)
    const [city, setCity] = useState(null)
    const {t} = useTranslation()
    const {data: regions, isLoading: isLoadingRegions} = useGetAllQuery({
        key: KEYS.organizationTerritory,
        url: URLS.organizationTerritory,
        params: {
            params: {
                limit: 100,
                level: 1
            }
        }
    })
    const {data: districts, isLoading: isLoadingDistricts} = useGetAllQuery({
        key: [KEYS.organizationTerritory,get(region,'id')],
        url: URLS.organizationTerritory,
        params: {
            params: {
                limit: 100,
                level: 2,
                parent_id:get(region,'id')
            }
        },
        enabled:!!(get(region,'id'))
    })
    const {data: budget, isLoading: isLoadingBudget} = useGetAllQuery({
        key: [KEYS.statistics, region, year, month],
        url: `${URLS.statistics}/${year}/${parseInt(month)}`,
        params: {
            params: {
                state_id: get(city,'id',get(region, 'id'))
            }
        },
        enabled: !!(get(region, 'id') && year)
    })
    const {data: periods, isLoading: isLoadingPeriod} = useGetAllQuery({
        key: [KEYS.statisticsByPeriod, year, region],
        url: `${URLS.statisticsByPeriod}/${year}`,
        params: {
            params: {
                state_id: get(city,'id',get(region, 'id'))
            }
        },
        enabled: !!(get(region, 'id') && year)
    })
    useEffect(() => {
        if (!isEmpty(get(regions, 'data', []))) {
            setRegion(head(get(regions, 'data', [])))
        }
    }, [regions])
    if (isLoadingRegions) {
        return <OverlayLoader/>;
    }
    return (
        <>
            {(isLoadingPeriod || isLoadingBudget) && <OverlayLoader/>}
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <Title>{t("Статистика по организациям")}</Title>

                </div>
            </div>
            <div className="grid  grid-cols-12 bg-[#006D85] rounded-2xl">
                <div className="col-span-3 bg-[#006D85]  rounded-2xl px-2.5 py-5">
                    <h3 className={'text-center text-white font-semibold mb-3'}>{t("Фонд государственного медицинского страхования Республики Узбекистан")}</h3>
                    <ul>
                        {
                            get(city,'id') && <li onClick={() => {
                                setCity(null)
                            }}
                                                  className={clsx('rounded text-center p-2.5 mt-3 text-white cursor-pointer text-xl flex justify-center items-center')}
                                                 ><ArrowLeft /><span className={'ml-2'}>{get(region, 'display')}</span></li>
                        }
                        {
                            !get(city,'id') && get(regions, 'data', []).map(item => <li onClick={() => setRegion(item)}
                                                                     className={clsx('rounded text-center p-2.5 mt-3 text-white cursor-pointer', {'!bg-white !text-[#00576A]': isEqual(get(region, 'id'), get(item, 'id'))})}
                                                                     key={get(item, 'id')}>{get(item, 'display')}</li>)
                        }
                        {
                            get(city,'id') && get(districts, 'data', []).map(item => <li onClick={() => setCity(item)}
                                                                                        className={clsx('rounded text-center p-2.5 mt-3 text-white cursor-pointer', {'!bg-white !text-[#00576A]': isEqual(get(city, 'id'), get(item, 'id'))})}
                                                                                        key={get(item, 'id')}>{get(item, 'display')}</li>)
                        }
                    </ul>
                </div>
                <div className="col-span-9 bg-white rounded-2xl p-4">
                    <Title sm className={'text-[#00576A] mb-3'}>{t("Бюджет")}</Title>
                    <YearMonthPicker hasBorder getYear={setYear} getMonth={setMonth}/>
                    <div className={'grid grid-cols-12 mt-4 gap-x-4'}>
                        <div className="col-span-7  h-[275px] shadow-lg rounded-2xl p-2.5 border border-[#D2D2D2]">
                            <h2 className={'text-xl font-semibold'}>{t("Аналитика")}</h2>
                            <BarChart data={get(periods, 'data.payload.data', [])}/>
                        </div>
                        <div className="col-span-5">
                            <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                                <div className="col-span-6 shadow p-3 rounded-xl border border-[#D2D2D2]">
                                    <div className="flex items-center mb-8">
                                        <img src={img1} alt="img" className={'mr-3'}/>
                                        <h4>{t("Выделенный бюджет")}</h4>

                                    </div>
                                    <NumericFormat displayType={'text'} thousandSeparator={" "}
                                                   className={'pl-4 text-[#00576A] text-xl'}
                                                   value={get(budget, 'data.payload.data.allocated_amount', 0)}
                                                   suffix={' UZS'}/>
                                </div>
                                <div className="col-span-6 shadow p-3 rounded-xl border border-[#D2D2D2]">
                                    <div className="flex items-center mb-8">
                                        <img src={img2} alt="img" className={'mr-3'}/>
                                        <h4>{t("Стоимость выписанных рецептов")}</h4>
                                    </div>
                                    <NumericFormat displayType={'text'} thousandSeparator={" "}
                                                   className={'pl-4 text-[#00576A] text-xl'}
                                                   value={get(budget, 'data.payload.data.orders_total_estimated_price', 0)}
                                                   suffix={' UZS'}/>
                                </div>
                                <div className="col-span-6 shadow p-3 rounded-xl border border-[#D2D2D2]">
                                    <div className="flex items-center mb-8">
                                        <img src={img3} alt="img" className={'mr-3'}/>
                                        <h4>{t("Остаток бюджета")}</h4>
                                    </div>
                                    <NumericFormat displayType={'text'} thousandSeparator={" "}
                                                   className={'pl-4 text-[#00576A] text-xl'}
                                                   value={get(budget, 'data.payload.data.spent_amount', 0)}
                                                   suffix={' UZS'}/>
                                </div>
                                <div className="col-span-6 shadow p-3 rounded-xl border border-[#D2D2D2]">
                                    <div className="flex items-center mb-8">
                                        <img src={img4} alt="img" className={'mr-3'}/>
                                        <h4>{t("Текущий баланс")}</h4>
                                    </div>
                                    <NumericFormat displayType={'text'} thousandSeparator={" "}
                                                   className={'pl-4 text-[#00576A] text-xl'}
                                                   value={get(budget, 'data.payload.data.current_balance', 0)}
                                                   suffix={' UZS'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-12 mt-6'}>
                            {year && !get(city,'id') &&  <GridView
                                onRowClick={(val)=>setCity(get(val,'city'))}
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                listKey={KEYS.statisticsRealization}
                                url={`${URLS.statisticsRealization}/${year}/${parseInt(month)}/states/${get(region, 'id')}/cities`}
                                columns={[
                                    {
                                        title: '№',
                                        key: 'display',
                                        render:({number})=>number
                                    },
                                    {
                                        title: t('Районы'),
                                        key: 'city.display',
                                    },
                                    {
                                        title: t('Стоимость выпис.рецептов'),
                                        key: 'orders_total_estimated_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                    {
                                        title: t('Реализованнве рецепты'),
                                        key: 'dispensed_total_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                    {
                                        title: t('Нереализованные р.'),
                                        key: 'not_dispensed_total_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                ]}/>}
                        </div>
                        <div className={'col-span-12 mt-6'}>
                            {year && get(city,'id') &&  <GridView
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                listKey={KEYS.statisticsRealization}
                                url={`${URLS.statisticsRealization}/${year}/${parseInt(month)}/states/${get(region, 'id')}/cities/${get(city, 'id')}`}
                                columns={[
                                    {
                                        title: '№',
                                        key: 'display',
                                        render:({number})=>number
                                    },
                                    {
                                        title: t('Organization'),
                                        key: 'organization.display',
                                    },
                                    {
                                        title: t('Стоимость выпис.рецептов'),
                                        key: 'orders_total_estimated_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                    {
                                        title: t('Реализованнве рецепты'),
                                        key: 'dispensed_total_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                    {
                                        title: t('Нереализованные р.'),
                                        key: 'not_dispensed_total_price',
                                        render:({value})=><NumericFormat displayType={'text'} thousandSeparator={' '} value={value} />
                                    },
                                ]}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticsContainer;