import React from "react";
import {useForm, FormProvider} from "react-hook-form";

const Form = ({
                  children,
                  formRequest,
                  footer = '',
                  classNames = '',
                  defaultValues = {},
                  resetFields = {}
              }) => {
    const {...methods} = useForm({defaultValues: defaultValues})

    const onSubmit = (data) => {
        formRequest({data, reset: methods.reset, setError: methods.setError});
    };

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                onReset={() => methods.reset(resetFields)}
                className={classNames}
            >
                {children}
                {footer}
            </form>
        </FormProvider>
    );
};

export default Form;
