import React, {useState} from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {get, values, includes} from "lodash";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {useTranslation} from "react-i18next";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Panel, Stack} from "rsuite";
import Title from "../title";

const Index = ({encounter_id}) => {
    const [selected, setSelected] = useState({})
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'procedures',
        keyName: 'uuid'
    });

    return (
        <>
            <Panel style={{overflow: 'unset'}} header={
                <Stack justifyContent="space-between">
                    <Title sm>{t("Surgical Interventions")}</Title>
                    <Button htmlType={'button'} onClick={() => append({encounter_id})}
                            classNames={'p-1.5'}><Plus size={20}/></Button>
                </Stack>
            } bordered className={'col-span-12'} shaded>
            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12  mb-3 ">
                    <div className={'grid grid-cols-12 gap-x-6 items-start'}>
                        <Field type={FIELDS.treeSelect}
                               key={KEYS.concepts}
                               property={{optionLabel: 'display', optionValue: 'id'}}
                               dataKey={'payload.data'}
                               defaultValue={get(field, 'concept.id')}
                               urlSearchParam={{name:get(field, 'concept.display') }}
                               url={`${URLS.concepts}?class_code=procedure&is_set=true`}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`procedures[${index}].concept.id`}
                               label={t('Intervention')}
                        />
                        <Field disabled={!selected[index] && includes(values(selected),true)} property={{onChange:(val)=>setSelected(prev=>({...prev,[index]:val}))}} type={FIELDS.switch}
                               classNames={'col-span-3 mx-auto mt-8'} name={`procedures[${index}].is_main`}
                               label={t('Is main?')}
                        />
                        <Field type={FIELDS.datepicker}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`procedures[${index}].proc_date`}
                               label={t('Date')}
                        />
                        { <div className="col-span-1 flex justify-end mt-6">
                            <Button htmlType={'button'} onClick={() => remove(index)}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
            </Panel>
        </>
    );
};

export default Index;
