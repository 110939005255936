import React from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ChevronLeft} from "react-feather";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {useGetAllQuery, useGetOneQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {ContentLoader, OverlayLoader} from "../../../components/loader";
import {find, get, isEqual, forEach} from "lodash";
import GridView from "../../../containers/grid-view";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {REPORT_TYPE_STATUS} from "../../../constants";
import Badge from "../../../components/badge";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";

const ChairmanMedicationRequestContainer = ({patient_id, group_id}) => {
    const {t} = useTranslation();
    const {data, isLoading} = useGetOneQuery({id: patient_id, url: URLS.patients, key: [KEYS.patients, patient_id]})
    const {mutate: confirmRequest, isLoading: isLoadingConfirm} = usePutQuery({listKeyId: KEYS.medicationRequestGroup})
    const {data: reasonList} = useGetAllQuery({
        key: KEYS.medicationRequestStatusReason,
        url: URLS.medicationRequestStatusReason
    })

    const confirmMedicationRequest = (_id) => {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: t('Are you sure?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
        }).then((result) => {
            if (result.isConfirmed) {
                confirmRequest({url: `${URLS.medicationRequest}/${_id}/confirmed`})
            }
        });
    }


    const cancelMedicationRequest = (_id) => {
        let selectOptions = ``;
        forEach(get(reasonList, 'data', []), (_reason) => {
            selectOptions += `<option value=${get(_reason, 'id')}>${get(_reason, 'display')}</option>`
        })
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: t('Are you sure?'),
            html: `<div><select class="form-input w-full" id="reason">${selectOptions}</select></div>`,
            inputValidator: (value) => {
                if (!value) {
                    return t("Required field!");
                }
            },
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
            preConfirm: () => {
                const selectedValue = document.getElementById('reason').value;
                if (!selectedValue) {
                    Swal.showValidationMessage(t('You need to select an option'));
                }
                return selectedValue;
            }
        }).then((result) => {
            if (result.isConfirmed) {
                confirmRequest({
                    url: `${URLS.medicationRequest}/${_id}/rejected`,
                    attributes: {reason_id: parseInt(result?.value)},
                    reason_text: ''
                })
            }
        });
    }


    if (isLoading) {
        return <OverlayLoader/>
    }


    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/chairman/medication-request?tab=group'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                    <Title className={'mt-3'}>{t("Рецепт")} <span
                        className={'text-2xl'}>({get(data, 'data.payload.patient.last_name')} {get(data, 'data.payload.patient.first_name')} {get(data, 'data.payload.patient.middle_name')})</span></Title>
                </div>
                <div className="col-span-12">
                    <Content sm>
                        <div className="grid grid-cols-12 gap-x-10">
                            <div className="col-span-12">
                                {isLoadingConfirm && <ContentLoader/>}
                                <GridView
                                    bordered
                                    dataKey={'data.payload.medication_request_group.medication_requests'}
                                    metaDataKey={'data.payload'}
                                    params={{
                                        status_code: REPORT_TYPE_STATUS.review,
                                        sort: '-updated_at',
                                    }}
                                    hasActionColumn={false}
                                    listKey={KEYS.medicationRequestGroup}
                                    url={`${URLS.medicationRequestGroup}/${group_id}`}
                                    columns={[
                                        {
                                            title: t('Тип рецепта'),
                                            key: 'type.display',
                                        },
                                        {
                                            title: t('Наименование'),
                                            key: 'medication.vproduct.display',
                                        },
                                        {
                                            title: t('Разовая доза'),
                                            key: 'medication.vproduct.active_ingredient_qty',
                                            render: ({
                                                         row
                                                     }) => ` ${get(row, 'instructions[0].per_dose_qty')} ${get(row, 'instructions[0].dosage_unit.display')}`
                                        },
                                        {
                                            title: t('Обшее количество ЛС'),
                                            key: 'instructions[0].quantity',
                                            render: ({
                                                         value,
                                                         row
                                                     }) => <>{get(row, 'instructions[0].timing.frequency.display')}
                                                <strong
                                                    className={'mx-1'}>*</strong> {get(row, 'instructions[0].timing.days_count')}
                                                <strong
                                                    className={'mx-1'}>*</strong> {get(row, 'instructions[0].per_dose_qty')} = {value}</>
                                        },
                                        {
                                            title: t('Способ введения'),
                                            key: 'instructions[0].route.display',
                                        },
                                        {
                                            title: t('Примечание'),
                                            key: 'instructions[0].patient_instruction',
                                            checkIsVisible: true
                                        },
                                        {
                                            title: t('Причина отмены'),
                                            key: 'status.reason_text',
                                            render: ({value = []}) => get(find(value, (_item) => isEqual(get(_item, 'status.code'), REPORT_TYPE_STATUS.rejected)), 'reason_text'),
                                            checkIsVisible: true
                                        },
                                        {
                                            title: t('Срок действия'),
                                            key: 'valid_until',
                                            render: ({value}) => dayjs(value).format("DD.MM.YYYY")
                                        },
                                        {
                                            title: t('Created date'),
                                            key: 'created_at',
                                            render: ({value}) => dayjs(value).format("DD.MM.YYYY HH:mm")
                                        },
                                        {
                                            title: t('Status'),
                                            render: ({row}) => isEqual(get(row, 'status.code'), 'in-review') ? <>
                                                <AllowedAccess
                                                    permissions={[config.PERMISSIONS.medication_request_confirm]}>
                                                    <button
                                                        type={"button"}
                                                        onClick={() => confirmMedicationRequest(get(row, 'id'))}
                                                        className={'mr-3 py-2.5 px-5  text-white min-w-[150px] rounded-lg  border bg-[#006D85] font-bold text-center  mt-3  items-center '}>
                                                        {t("Утвердить")}
                                                    </button>
                                                </AllowedAccess>
                                                <AllowedAccess
                                                    permissions={[config.PERMISSIONS.medication_request_cancel]}>
                                                    <button
                                                        type={"button"}
                                                        onClick={() => cancelMedicationRequest(get(row, 'id'))}
                                                        className={'mr-3 py-2.5 px-5  text-[#006D85] min-w-[150px] rounded-lg   border border-[#006D85] font-bold text-center  mt-3  items-center '}>
                                                        {t("Отменён")}
                                                    </button>
                                                </AllowedAccess>
                                            </> : <Badge
                                                status={get(row, 'status.code')}>{get(row, 'status.display')}</Badge>
                                        },
                                    ]}/>
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        </>
    );
}

export default ChairmanMedicationRequestContainer;
