import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {get, hasIn, isFunction, isNil} from "lodash";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {NumericFormat} from 'react-number-format';


const NumberFormatInput = ({
                               name,
                               params,
                               property,
                               defaultValue = null,
                               label,
                               classNames = '',
                             icon= '', errorIcon = ''
                           }) => {
    const {
        control, formState: {errors}, getValues = () => {
        }, watch = () => {
        }, setValue
    } = useFormContext()
    const {t} = useTranslation()
    const [val, setVal] = useState(0)
  const isError = hasIn(errors, name);
    useEffect(() => {
        if(!isNil(defaultValue)) {
            setVal(defaultValue)
        }
    }, [defaultValue])
    useEffect(() => {
        setValue(name, val)
        if (isFunction(get(property, 'onChange'))) {
            get(property, 'onChange')(getValues(name), name)
        }
    }, [val]);
    return (
        <div className={clsx("form-group", classNames)}>
            <label className={'form-label flex'}>{label}
              {
                icon ? <img  className={'ml-1'} src={isError ? errorIcon : icon} alt="icon" /> : ""
              }
            </label>
            <Controller
                as={NumericFormat}
                control={control}
                name={name}
                rules={params}
                defaultValue={defaultValue}
                render={({field}) => (
                    <NumericFormat
                        {...field}
                        value={val}
                        className={clsx('form-input  w-full', get(property, 'className'), {'border-red-600': hasIn(errors, name)})}
                        placeholder={get(property, "placeholder")}
                        suffix={get(property, "suffix", '')}
                        thousandSeparator={get(property, "thousandSeparator", " ")}
                        isNumericString={true}
                        onValueChange={(value) => setVal(value.floatValue)}
                        allowNegative={get(property, "allowNegative", false)}
                        disabled={get(property, 'disabled', false)}
                    />
                )
                }
            />
            {get(errors, `${name}.type`) === "required" &&
                <span className={'form-error'}>{t('Заполните обязательное поле')}</span>}
            {get(errors, `${name}.type`) === 'validation' &&
                <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}
            {get(errors, `${name}.type`) === 'pattern' &&
                <span className={'form-error'}>{get(errors, `${name}.message`)}</span>}

        </div>
    );
};

export default NumberFormatInput;
