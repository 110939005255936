import React, {useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {get} from "lodash"
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Plus} from "react-feather";
import Search from "../../../components/search"
import SelectComponent from "../../../components/select";
import {useGetAllQuery, usePostQuery} from "../../../hooks/api";
import {EXCEL_EXPORT_TYPE, FIELDS} from "../../../constants";
import downloadIcon from "../../../assets/icons/download.svg";
import {toast} from "react-toastify";
import Button from "../../../components/button";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import {hasPermission} from "../../../services/auth/HasAccess";
import {useStore} from "../../../store";
import dayjs from "dayjs";
import Badge from "../../../components/badge";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";


const Patient066ListContainer = () => {
    const user = useStore(state => get(state, "user", null))
    const [loadingExport, setLoadingExport] = useState(false)
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [filter, setFilter] = useState({sort: '-updated_at'})
    const {t} = useTranslation();

    const {
        refetch
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.Patient}`,
        cb: {
            success: (res) => {
                toast.success(get(res, 'message'))
                setLoadingExport(false)
            },
            fail: () => {
                setLoadingExport(false)
            }
        }
    })


    const columns = [
        {
            title: t('Организация'),
            key: 'organization.display',
        },

        {
            title: t('Пациенты'),
            key: 'patient',
            render: ({value}) => <Link className={'text-blue-500'}
                                       to={`/patient/view/${get(value, 'id')}`}>{`${get(value, 'last_name')} ${get(value, 'first_name')} ${get(value, 'middle_name')}`}</Link>
        },
        {
            title: t('Дата поступления'),
            key: 'period_start',
            render: ({value}) => value ? dayjs(value).format("DD.MM.YYYY") : ''
        },
        {
            title: t('Дата выписки'),
            key: 'discharge.discharge_date',
            render: ({value}) => value ? dayjs(value).format("DD.MM.YYYY") : ''
        },
        {
            title: t('Статус'),
            key: 'status.code',
            render: ({value, row}) => <Badge
                status={value}>{get(row, 'status.display')}</Badge>
        },
    ]

    const sortOptions = [
        {label: t('Дата обновления (по возрастанию)'), value: "updated_at"},
        {label: t('Дата обновления (по убыванию)'), value: "-updated_at"},
        {label: t('Имя (по возрастанию)'), value: "first_name"},
        {label: t('Имя (по убыванию)'), value: "-first_name"},
        {label: t('Фамилия (по возрастанию)'), value: "last_name"},
        {label: t('Фамилия (по убыванию)'), value: "-last_name"}
    ];

    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6">
                    <Title>{t("Поступление в стационар")}</Title>
                </div>
                <div className="col-span-6 flex items-center justify-end">
                    <AllowedAccess permissions={[config.PERMISSIONS.patient_export]}>
                        <Button classNames={'!text-sm !pr-5'} loading={loadingExport} onClick={() => {
                            setLoadingExport(true);
                            refetch();
                        }} icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>}>{t("Export")}</Button>
                    </AllowedAccess>
                </div>

                <div className="col-span-12 mt-6">
                    <GridView
                        isContentLoader
                        filters={<div className={' py-4 px-2 '}>
                            <Form resetFields={{
                                name: '',
                                pin: '',
                                sort: '-updated_at',
                                state_id: null,
                                district_id: null,
                                city_id: null,
                                status_id:null,
                                organization_id:null
                            }}
                                  defaultValues={{...filter}}
                                  classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                  formRequest={({data}) => {
                                      const {
                                          name,
                                          pin,
                                          sort,
                                          state_id,
                                          district_id,
                                          city_id,
                                          date_from,
                                          date_to,
                                          status_id,
                                          organization_id
                                      } = data;
                                      setFilter({
                                          name,
                                          pin,
                                          sort: get(sort, 'value'),
                                          state_id: get(state_id, 'id'),
                                          district_id: get(district_id, 'id'),
                                          city_id: get(city_id, 'id'),
                                          date_from: date_from,
                                          date_to: date_to,
                                          status_id: get(status_id, 'id'),
                                          organization_id: get(organization_id, 'id'),
                                      })
                                  }
                                  }>
                                <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                       placeholder={t('Ф.И.О')}/>
                                <Field type={FIELDS.inputMask}
                                       params={{pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                                       classNames={'col-span-3'}
                                       name={'pin'}
                                       property={{
                                           placeholder: t('ПИНФЛ'),
                                           mask: '99999999999999',
                                           maskChar: '_',
                                       }}
                                />


                                <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'state_id'}
                                       placeholder={t('State')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                       enabled={!!(regionId)}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'city_id'}
                                       placeholder={t('City')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'district_id'}
                                       placeholder={t('District')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field defaultValue={null} isClearable classNames={'col-span-3'}
                                       type={FIELDS.datepicker} name={'date_from'}
                                       placeholder={t('Date from')}/>
                                <Field defaultValue={null} isClearable classNames={'col-span-3'}
                                       type={FIELDS.datepicker} name={'date_to'} placeholder={t('Date to')}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} name={'status_id'}
                                       placeholder={t('Status')} url={URLS.encounterStatus}
                                       keyId={KEYS.encounterStatus}/>
                                <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} dataKey={'payload.data'}
                                       name={'organization_id'}
                                       placeholder={t('Organization')} url={URLS.organizations}
                                       keyId={KEYS.organizations}/>
                                <Field type={FIELDS.select}
                                       classNames={'col-span-3'}
                                       name={'sort'}
                                       placeholder={t('Sort')}
                                       options={sortOptions}
                                       property={{optionValue: 'value', optionLabel: 'label'}}
                                />
                                <div className={'col-span-6 mt-1.5'}>
                                    <div className="flex items-center">
                                        <button type={'submit'}
                                                className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                            {t('Фильтр')}
                                        </button>
                                        <button onClick={() => setFilter({})} type={'reset'}
                                                className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                            {t('Очистить фильтр')}
                                        </button>
                                    </div>
                                </div>
                            </Form>

                        </div>}
                        updateUrl={'/encounters/imp/update'}
                        viewUrl={'/encounters/imp'}
                        dataKey={'data.payload.data'}
                        metaDataKey={'data.payload.meta'}
                        params={{
                            ...filter
                        }}
                        hasActionColumn
                        listKey={KEYS.encounterImp}
                        url={URLS.encounterImp}
                        columns={columns}
                        hasDeleteColumn={false}
                        hasEditColumn={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default Patient066ListContainer;
