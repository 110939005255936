import React from 'react';
import {useParams} from "react-router-dom"
import ChairmanMedicationRequestContainer from "../containers/ChairmanMedicationRequestContainer";

const ChairmanMedicationRequestPage = () => {
    const {group_id,patient_id} = useParams()
    return (
        <>
            <ChairmanMedicationRequestContainer group_id={group_id} patient_id={patient_id}/>
        </>
    );
};

export default ChairmanMedicationRequestPage;