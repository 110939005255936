import React from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {useTranslation} from "react-i18next";
import {get} from "lodash"
import {useFieldArray, useFormContext} from "react-hook-form";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {Panel, Stack} from "rsuite";
import Title from "../title";

const Index = () => {
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'newborns',
        keyName: 'uuid'
    });
    return (
        <>
            <Panel style={{overflow: 'unset'}} header={
                <Stack justifyContent="space-between">
                    <Title sm>{t("New Born")}</Title>
                    <Button htmlType={'button'} onClick={() => append({})}
                            classNames={'p-1.5'}><Plus size={20}/></Button>
                </Stack>
            } bordered className={'col-span-12'} shaded>
                {
                    fields.map((field, index) => <div key={get(field, 'uuid')}
                                                      className="col-span-12 mb-3">
                        <div className={'grid grid-cols-12 gap-x-6 items-start'}>

                            <Field type={FIELDS.inputMask}
                                   params={{required: true, pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                                   classNames={'col-span-4'} name={`newborns[${index}].mother_pin`}
                                   label={t('Mother pin')}
                                   disabled={get(field,'mother_pin')}
                                   property={{
                                       placeholder: t('ПИНФЛ'),
                                       mask: '99999999999999',
                                       maskChar: '_',
                                   }}
                            />
                            <Field type={FIELDS.input}
                                   params={{valueAsNumber: true}}
                                   property={{type:'number'}}
                                   classNames={'col-span-2'} name={`newborns[${index}].weight`}
                                   label={t('Вес')}
                            />
                            <Field type={FIELDS.input}
                                   property={{type:'number'}}
                                   params={{valueAsNumber: true}}
                                   classNames={'col-span-2'} name={`newborns[${index}].height`}
                                   label={t('Рост')}
                            />
                            <Field type={FIELDS.datepicker}
                                   params={{required: true}}
                                   classNames={'col-span-3'} name={`newborns[${index}].birth_date`}
                                   label={t('Birthdate')}
                            />
                            <div className="col-span-1  mt-6 flex justify-end">
                                <Button htmlType={'button'} onClick={() => remove(index)}
                                        classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                            </div>
                        </div>
                    </div>)
                }
            </Panel>
        </>
    );
};

export default Index;
