import React, {useEffect, useState} from 'react';
import {MONTHS, YEARS} from "../../constants";
import {get, isEqual} from "lodash";
import clsx from "clsx";
import dayjs from "dayjs";
import SelectComponent from "../../components/select"
import {useTranslation} from "react-i18next";

const Index = ({getYear = () => {},getMonth=()=>{},hasBorder=false}) => {
    const {t} = useTranslation()
    const [month, setMonth] = useState(dayjs().format("MM"))
    const [year, setYear] = useState({value: dayjs().format("YYYY"), label: dayjs().format("YYYY")})
    useEffect(()=>{
        if(get(year,'value')){
            getYear(get(year,'value'));
        }
        if(month){
            getMonth(month);
        }
    },[year,month])

    return (<div className={'flex items-center'}>
            <SelectComponent
                md
                setValue={(val) => setYear(val)}
                value={year}
                options={YEARS}
                isClearable={false}
                isSearchable={false}
                defaultValue={year}
            />
            <div className={'flex ml-1 items-center'}>
                {MONTHS.map((item) => <div onClick={() => setMonth(get(item, 'value'))}
                                           className={clsx('bg-white px-5 py-2.5 mr-1 font-semibold cursor-pointer', {'!bg-[#006D85] !text-white': isEqual(get(item, 'value'), month),'border':hasBorder})}>{t(get(item, 'label'))}</div>)}
            </div>
        </div>
    );
};

export default Index;