import React, {useState} from 'react';
import Field from "../../containers/form/field";
import orgIcon from "../../assets/icons/org.svg";
import {get, isObject} from "lodash";
import {useGetAllQuery} from "../../hooks/api";
import {KEYS} from "../../constants/keys";
import {URLS} from "../../constants/urls";
import {useTranslation} from "react-i18next";
import orgRedBOrderIcon from "../../assets/icons/org-red-border.svg";
import {FIELDS} from "../../constants";

const Index = ({data, name = 'locations', dataKey = null}) => {
    const {t} = useTranslation()
    let [regionId, setRegionId] = useState(null);
    let [districtId, setDistrictId] = useState(null);
    const {data: organizationCountryList} = useGetAllQuery({
        key: KEYS.organizationCountry,
        url: URLS.organizationCountry,
        params: {
            params: {
                limit: 1000
            }
        },
    })

    const {data: organizationRegions, isLoading: isLoadingRegions} = useGetAllQuery({
        key: KEYS.organizationTerritory,
        url: URLS.organizationTerritory,
        params: {
            params: {
                limit: 100,
                level: 1
            }
        }
    })
    const {data: organizationDistricts} = useGetAllQuery({
        key: [KEYS.organizationTerritory, regionId],
        url: URLS.organizationTerritory,
        params: {
            params: {
                limit: 100,
                level: 2,
                parent_id: isObject(regionId) ? get(regionId, 'value') : regionId
            },
        },
        enabled: !!(regionId)
    })
    const {data: organizationNeighbors} = useGetAllQuery({
        key: [KEYS.organizationTerritory, districtId],
        url: URLS.organizationTerritory,
        params: {
            params: {
                limit: 1000,
                level: 3,
                parent_id: isObject(districtId) ? get(districtId, 'value') : districtId
            },
        },
        enabled: !!(districtId)
    })
    return (
        <>
            <Field type={FIELDS.input} isDisabled
                   defaultValue={1}
                   classNames={'col-span-12'} name={`${name}[0].address.use.id`}
                   property={{type: 'hidden'}}
            />
            <Field type={FIELDS.select} isDisabled
                   defaultValue={{id: 244, display: "O'ZBEKISTON", code: "UZB"}}
                   classNames={'col-span-4'} name={dataKey ? `${name}[0].country` : `${name}[0].address.country`}
                   label={t('Страна')}
                   params={{required: true}}
                   options={get(organizationCountryList, 'data', [])}/>
            <Field  type={FIELDS.select}
                   defaultValue={dataKey ? get(data, `${name}[0].state`) : get(data, `${name}[0].address.state`)}
                   classNames={'col-span-4'}
                   name={dataKey ? `${name}[0].state` : `${name}[0].address.state`}
                   label={t('Регион')}
                   params={{required: true}}
                   property={{
                       onChange: (val) => {
                           setRegionId(get(val, 'id'))
                       }
                   }}
                   options={get(organizationRegions, 'data', [])}/>
            <Field type={FIELDS.select}
                   defaultValue={dataKey ? get(data, `${name}[0].city`) : get(data, `${name}[0].address.city`)}
                   classNames={'col-span-4'}
                   name={dataKey ? `${name}[0].city` : `${name}[0].address.city`}
                   label={t('Район')}
                   params={{required: true}}
                   property={{onChange: (val) => setDistrictId(get(val, 'id'))}}
                   options={get(organizationDistricts, 'data', [])}
            />
            <Field type={FIELDS.select}
                   defaultValue={dataKey ? get(data, `${name}[0].district`) : get(data, `${name}[0].address.district`)}
                   classNames={'col-span-4'}
                   name={dataKey ? `${name}[0].district` : `${name}[0].address.district`}
                   label={t('Махалля')}
                   params={{required: true}}
                   options={get(organizationNeighbors, 'data', [])}
            />
            <Field type={FIELDS.input}
                   defaultValue={dataKey ? get(data, `${name}[0].line`) : get(data, `${name}[0].address.line`)}
                   classNames={'col-span-4'}
                   name={dataKey ? `${name}[0].line` : `${name}[0].address.line`}
                   params={{required: true}}
                   placeholder={t('Улица')}
                   label={t('Улица')}
            />
            <Field type={FIELDS.input}
                   defaultValue={dataKey ? get(data, `${name}[0].block`, null) : get(data, `${name}[0].address.block`, null)}
                   classNames={'col-span-2'}
                   name={dataKey ? `${name}[0].block` : `${name}[0].address.block`}
                   params={{required: true}}
                   placeholder={t('Дом')}
                   label={t('Дом')}
            />
            <Field params={{pattern: {value: /^[0-9]{6}$/, message: 'Invalid value'}}}
                   type={FIELDS.inputMask} property={{mask: '999999'}}
                   defaultValue={dataKey ? get(data, `${name}[0].postal_code`) : get(data, `${name}[0].address.postal_code`)}
                   classNames={'col-span-2'} name={`${name}[0].address.postal_code`}
                   placeholder={t('Почтовый индекс')}
                   label={t('Почтовый индекс')}
            />
        </>
    );
};

export default Index;
