import React, {useEffect} from 'react';
import InputMask from 'react-input-mask';
import {Controller, useFormContext} from "react-hook-form";
import {get, hasIn, isFunction} from "lodash";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import Label from "../../../components/label";
import RSwitch from 'react-switch';


const Switch = ({
                    name,
                    params,
                    property,
                    defaultValue = false,
                    label,
                    classNames = '',
                    disabled = false
                }) => {
    const {
        control, formState: {errors}, getValues = () => {
        }, watch = () => {
        }
    } = useFormContext()
    const {t} = useTranslation()
    const isError = hasIn(errors, name);
    useEffect(() => {
        if (isFunction(get(property, 'onChange'))) {
            get(property, 'onChange')(getValues(name), name)
        }
    }, [watch(name)]);

    return (
        <div className={clsx("form-group flex items-center", classNames)}>
            <Controller
                as={InputMask}
                control={control}
                name={name}
                rules={params}
                defaultValue={defaultValue}
                render={({field: {value, onChange}}) => (
                    <RSwitch
                        onChange={onChange}
                        checked={value}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        disabled={disabled}
                    />
                )}
            />
            <div className={'ml-3 mt-1'}>
                <Label isError={isError} isRequired={get(params, 'required', false)}>{label}</Label>
            </div>
        </div>
    );
};

export default Switch;
