import React from 'react';
import {NavLink, Outlet} from "react-router-dom";
import bgImage from "../../assets/apteka.jpg"
import Brand from "../../components/brand";
import Language from "../../components/language";
import Profile from "../../components/profile";
import Notifications from "../../components/notifications";
import Menu from "../../components/menu";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    return (
        <div className={'min-h-screen bg-cover bg-no-repeat bg-center px-10 py-5'}
             style={{backgroundImage: `url(${bgImage})`}}>
            <div className={'flex items-center justify-between mb-10'}>
                <Brand/>
                <Menu>
                    <li className={'menu__item group'}>
                        <NavLink className={'menu__item_link'} to={'/pharmacy-search'}>{t('Льготные рецепты')}</NavLink>
                    </li>
                    <li className={'menu__item group'}>
                        <NavLink className={'menu__item_link'} to={'/pharmacy-report'}>{t('Отчетность')}</NavLink>
                    </li>
                </Menu>
                <div className={'flex items-center justify-end'}>

                    <Language/>
                    <Notifications/>
                    <Profile/>
                </div>
            </div>
            <Outlet/>
        </div>
    );
};

export default Index;