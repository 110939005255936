import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import AuthLayout from "../layouts/auth";
import PharmacyLayout from "../layouts/pharmacy";
import IsAuth from "../services/auth/IsAuth";
import IsGuest from "../services/auth/IsGuest";
import {OverlayLoader} from "../components/loader";
import DashboardLayout from "../layouts/dashboard";
import ErrorPage from "../modules/auth/pages/ErrorPage";
import ProfilePage from "../modules/auth/pages/ProfilePage";
import LogoutPage from "../modules/auth/pages/LogoutPage";
import ForbiddenPage from "../modules/auth/pages/ForbiddenPage";
import NotFoundPage from "../modules/auth/pages/NotFoundPage";
import SSOCallbackPage from "../modules/auth/pages/SSOCallbackPage";
import PractitionersPage from "../modules/hrm/pages/PractitionersPage";
import PatientsPage from "../modules/patient/pages/PatientsPage";
import PatientCreatePage from "../modules/patient/pages/PatientCreatePage";
import PatientUpdatePage from "../modules/patient/pages/PatientUpdatePage";
import PatientPage from "../modules/patient/pages/PatientPage";
import PatientEpisodeOfCarePage from "../modules/patient/pages/PatientEpisodeOfCarePage";
import PatientEncounterPage from "../modules/patient/pages/PatientEncounterPage";
import PatientRecipePage from "../modules/patient/pages/PatientRecipePage";
import MedicationRequestListPage from "../modules/medication-request/pages/ListPage";
import MedicationRequestChairmanListPage from "../modules/medication-request/pages/ChairmanListPage";
import ChairmanMedicationRequestPage from "../modules/medication-request/pages/ChairmanMedicationRequestPage";
import PharmacyPage from "../modules/pharmacy/pages/ViewPage";
import PharmacyReportPage from "../modules/pharmacy/pages/ReportPage";
import BudgetPage from "../modules/budget/pages/BudgetPage";
import StatisticsPage from "../modules/statistics/pages/StatisticsPage";
import BudgetInsertPage from "../modules/budget/pages/BudgetInsertPage";
import BudgetReportPage from "../modules/report/pages/ReportPage";
import {AllowedAccess} from "react-permission-role";
import config from "../config";
import Forbidden from "../components/forbidden";
import Patient066CreatePage from "../modules/patient/pages/Patient066CreatePage";
import Patient066ListPage from "../modules/patient/pages/Patient066ListPage";
import Patient066ViewPage from "../modules/patient/pages/Patient066ViewPage";
import Patient066UpdatePage from "../modules/patient/pages/Patient066UpdatePage";
import MapPage from "../modules/statistics/pages/MapPage";
import PractitionerPage from "../modules/hrm/pages/PractitionerPage";
import BudgetByOrganizationPage from "../modules/budget/pages/BudgetByOrganizationPage";

const LoginPage = lazy(() => import("../modules/auth/pages/LoginPage"));
const OrganizationsPage = lazy(() => import("../modules/hrm/pages/OrganizationsPage"));
const OrganizationPage = lazy(() => import("../modules/hrm/pages/OrganizationPage"));
const TranslationsPage = lazy(() => import("../modules/settings/pages/TranslationsPage"));


const Router = () => {
    return (
        <Suspense fallback={<OverlayLoader/>}>
            <BrowserRouter>
                <IsAuth>
                    <Routes>
                        <Route path={"/"} element={<DashboardLayout/>}>
                            <Route index element={<AllowedAccess roles={[config.ROLES.ROLE_PHARMACIST]}
                                                                 renderAuthFailed={<StatisticsPage/>}><Navigate
                                to={'/pharmacy'} replace/></AllowedAccess>}/>
                            <Route path={"hrm"}>
                                <Route path={'organizations'} element={<OrganizationsPage/>}/>
                                <Route path={'organization/:id'} element={<OrganizationPage/>}/>
                                <Route path={'practitioners'} element={<PractitionersPage/>}/>
                                <Route path={'practitioners/view/:id'} element={<PractitionerPage/>}/>
                            </Route>
                            <Route path={'settings'}>
                                <Route path={'translations'} element={<TranslationsPage/>}/>
                            </Route>
                            <Route path={'encounters'}>
                                <Route path={'imp'} element={<Patient066ListPage/>}/>
                                <Route path={'imp/:id'} element={<Patient066ViewPage/>}/>
                            </Route>
                            <Route path={"patient"}>
                                <Route index element={<AllowedAccess permissions={[config.PERMISSIONS.patient_view]}
                                                                     renderAuthFailed={
                                                                         <Forbidden/>}><PatientsPage/></AllowedAccess>}/>
                                <Route path={"create"}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.patient_create]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><PatientCreatePage/></AllowedAccess>}/>
                                <Route path={"update/:id"}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.patient_edit]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><PatientUpdatePage/></AllowedAccess>}/>
                                <Route path={"view/:id"}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.patient_view]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><PatientPage/></AllowedAccess>}/>
                                <Route path={"episode-of-cares/:id"}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.episode_of_care_view]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><PatientEpisodeOfCarePage/></AllowedAccess>}/>
                                <Route path={"encounter/:id"}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.encounter_imp_view]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><PatientEncounterPage/></AllowedAccess>}/>
                                <Route path={"medication-request/:id"} element={<AllowedAccess
                                    permissions={[config.PERMISSIONS.medication_request_view]}
                                    renderAuthFailed={<Forbidden/>}><PatientRecipePage/></AllowedAccess>}/>
                                <Route path={"066/:id"} element={<AllowedAccess
                                    permissions={[config.PERMISSIONS.medication_request_view]}
                                    renderAuthFailed={<Forbidden/>}><Patient066CreatePage/></AllowedAccess>}/>
                                <Route path={"066/update/:patient_id/:id"} element={<AllowedAccess
                                    permissions={[config.PERMISSIONS.medication_request_view]}
                                    renderAuthFailed={<Forbidden/>}><Patient066UpdatePage/></AllowedAccess>}/>
                            </Route>
                            <Route path={"medication-request"}>
                                <Route index element={<AllowedAccess
                                    permissions={[config.PERMISSIONS.medication_request_view]}
                                    renderAuthFailed={<Forbidden/>}><MedicationRequestListPage/></AllowedAccess>}/>
                            </Route>
                            <Route path={"chairman"}>
                                <Route path={"medication-request"} element={<MedicationRequestChairmanListPage/>}/>
                                <Route path={"medication-request/:patient_id/:group_id"}
                                       element={<ChairmanMedicationRequestPage/>}/>
                            </Route>
                            <Route path={"budget"}>
                                <Route index element={<BudgetPage/>}/>
                                <Route path={'report'} element={<BudgetReportPage/>}/>
                                <Route path={'insert'} element={<BudgetInsertPage/>}/>
                                <Route path={'by-organization'} element={<BudgetByOrganizationPage/>}/>
                            </Route>
                            <Route path={"statistics"}>
                                <Route index element={<AllowedAccess permissions={[config.PERMISSIONS.dashboard_view]}
                                                                     renderAuthFailed={
                                                                         <Forbidden/>}><StatisticsPage/></AllowedAccess>}/>
                                <Route path={'cartography'}
                                       element={<AllowedAccess permissions={[config.PERMISSIONS.dashboard_view]}
                                                               renderAuthFailed={
                                                                   <Forbidden/>}><MapPage/></AllowedAccess>}/>
                            </Route>
                            <Route path={"/error"} element={<ErrorPage/>}/>
                            <Route path={"/profile"} element={<ProfilePage/>}/>
                            <Route path="/auth/logout" element={<LogoutPage/>}/>
                            <Route path={"/403"} element={<ForbiddenPage/>}/>
                            <Route path={"/404"} element={<NotFoundPage/>}/>
                            <Route path={"auth"} element={<Navigate to={'/'} replace/>}/>
                            <Route path={"auth/callback"} element={<Navigate to={'/'} replace/>}/>
                            <Route path={"*"} element={<Navigate to={'404'}/>}/>
                        </Route>
                        <Route element={<PharmacyLayout/>}>
                            <Route path={'/pharmacy-search'}
                                   element={<AllowedAccess permissions={[config.PERMISSIONS.medication_dispense_view]}
                                                           renderAuthFailed={
                                                               <Forbidden/>}><PharmacyPage/></AllowedAccess>}/>
                            <Route path={"/pharmacy-report"} element={<PharmacyReportPage/>}/>
                        </Route>
                    </Routes>
                </IsAuth>
                <IsGuest>
                    <Routes>
                        <Route path={"/auth"} element={<AuthLayout/>}>
                            <Route index element={<LoginPage/>}/>
                            <Route path={'callback'} element={<SSOCallbackPage/>}/>
                        </Route>
                        <Route path={"*"} element={<Navigate to={'/auth'} replace/>}/>
                    </Routes>
                </IsGuest>
            </BrowserRouter>
        </Suspense>
    );
};

export default Router;
