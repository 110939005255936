import React from 'react';
import avatar from "../../assets/images/avatar.png";
import {get} from "lodash";
import arrowIcon from "../../assets/icons/arrow-down.svg";
import {LogOut} from "react-feather";
import Dropdown from "../dropdown";
import {useStore} from "../../store";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Index = () => {
    const user = useStore(state => get(state, "user", null))
    const navigate = useNavigate();
    const {t} = useTranslation()

    const logout = () => {
        navigate("/auth/logout");
    }
    return (
        <>
            <Dropdown btn={<div className="cursor-pointer flex items-center">
                <img className={'mr-2'} src={avatar} alt="avatar"/>
                <span className={'font-semibold text-secondary-300'}>{get(user, 'first_name')}</span>
                <img src={arrowIcon} alt=""/>
            </div>}>
                <ul>
                    <li className={'cursor-pointer p-2 font-semibold flex items-center'} onClick={logout}>
                        <LogOut className={'mr-1.5'} size={18}/><span>{t("Logout")}</span></li>
                </ul>
            </Dropdown>
        </>
    );
};

export default Index;