import React, {useState} from 'react';
import {MapContainer as LMapContainer} from "react-leaflet/MapContainer";
import {GeoJSON, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import regionsJsonData from "../../mock/regions.json";
import districtsJsonData from "../../mock/districts.json";

const Index = () => {
    const [map, setMap] = useState(null)
    const [stroke, setStroke] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
    const [fill, setFill] = useState(["#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00"])
    const fillColor = ["#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00", "#0000ff00"]
    const [distStroke, setDistStroke] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
    const [soato, setSoato] = useState(null)
    const [distr, setDistr] = useState([]);
    const setDistricts = (s) => {
        setDistr([])
        setSoato(s)
        const dists = [];
        districtsJsonData['features'].map(a => {
            if (a.properties.soato !== null) {
                if (a.properties.soato.toString().includes(s)) {
                    dists.push(a)
                }
            }
            return false
        })
        setDistr(dists)
    }
    console.log('soato', soato)
    console.log('fill', fill)
    return (
        <div style={{height: "80vh"}}>
            <LMapContainer center={[41.559953, 63.292248]} zoom={6} style={{height: "100%", width: "100%"}}
                           ref={setMap}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {
                    regionsJsonData['features'].map((reg, i) => {
                        return <GeoJSON  eventHandlers={{
                            click: (e) => {
                                map.fitBounds(e.target.getBounds())
                                setDistricts(reg.properties['soato'])
                                let a = fillColor
                                a[i] = "#FF7F7F"
                                setFill(a)
                            },
                            mouseout: () => {
                                let str = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                                setStroke(str)
                            },
                            mouseover: () => {
                                let str = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                                str[i] = 3
                                setStroke(str)
                            },

                        }} pathOptions={{fillColor: 'red', color: 'green', weight: stroke[i]}} key={i}
                                        data={reg?.geometry}/>
                    })
                }
                {
                    distr.map((dis, i) => {
                        return (
                            <div key={i}>
                                <GeoJSON title={"Districts"} eventHandlers={{
                                    click: (e) => {
                                        map.fitBounds(e.target.getBounds())
                                    },
                                    mouseout: () => {
                                        setDistStroke([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
                                    },
                                    mouseover: () => {
                                        let str2 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                                        str2[i] = 3
                                        setDistStroke(str2)
                                    },

                                }} pathOptions={{fillColor: '#0000ff00', color: '#3388ff', weight: distStroke[i]}}
                                         key={i} data={dis?.geometry} filter={false}/>

                            </div>
                        )
                    })
                }
            </LMapContainer>
        </div>
    );
};

export default Index;
