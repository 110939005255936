import React, {useState} from 'react';
import Content from "../../../components/content";
import Title from "../../../components/title";
import {NavLink, useNavigate} from "react-router-dom";
import Input from "../../../components/input";
import {useGetAllQuery, usePostQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {get, head, isEmpty, isNil, isEqual} from "lodash";
import dayjs from "dayjs";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {KEYS} from "../../../constants/keys";
import {useTranslation} from "react-i18next";
import NoData from "../../../components/no-data";
import Swal from "sweetalert2";
import {Button} from "rsuite";
import {OverlayLoader} from "../../../components/loader";
import clsx from "clsx";
import {FIELDS} from "../../../constants";

const ViewContainer = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [qty, setQty] = useState([0])
    const [price, setPrice] = useState([0])
    const [brand, setBrand] = useState([])
    const [data, setData] = useState([]);

    const {mutate: searchRequest, isLoading: isLoadingSearch} = usePostQuery({listKeyId: KEYS.searchDispensable});
    const {mutate: confirmRequest, isLoading: isLoadingConfirm} = usePostQuery({listKeyId: KEYS.medicationDispense});
    const {mutate: verificationRequest, isLoading: isLoadingVerification} = usePostQuery({
        listKeyId: 'verification',
        hideSuccessToast: true
    });
    const {
        mutate: authorizationRequest,
        isLoading: isLoadingAuthorization
    } = usePostQuery({listKeyId: 'authorization', hideSuccessToast: true});
    const searchByDispensable = ({data:attrs}) => {
        searchRequest({
            url: URLS.searchDispensable,
            attributes: {
                ...attrs
            }
        }, {
            onSuccess: ({data:response}) => {
                setData(get(response,'payload.data',[]))
            },
            onError:()=>{
                setData([])
            }
        })
    }
    const confirm = ({data: _data}, _groupId) => {
        verificationRequest({url: `${URLS.medicationRequestByGroups}/${_groupId}/verification`}, {
            onSuccess: (res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: t('Enter sms code'),
                    input: "number",
                    inputValidator: (value) => {
                        if (!value) {
                            return "Required field!";
                        }
                    },
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3F6A83',
                    confirmButtonText: t('Отправить'),
                    cancelButtonText: t('Отмена'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        authorizationRequest({
                            url: `${URLS.medicationRequestByGroups}/${_groupId}/authorization`,
                            attributes: {
                                otp: result?.value
                            }
                        }, {
                            onSuccess: ({data: response}) => {
                                if (get(response, 'payload.dispense_authorization.token')) {
                                    confirmRequest({
                                        url: URLS.medicationDispenseUpsert,
                                        attributes: {
                                            medication_dispenses: get(_data, 'medication_dispenses', []).map((_dispense) => ({
                                                ..._dispense,
                                                token: get(response, 'payload.dispense_authorization.token')
                                            }))
                                        }
                                    }, {
                                        onSuccess: () => {
                                            navigate('/pharmacy-report')
                                        }
                                    })
                                }
                            }
                        })
                    }
                });
            }
        })
    }
    return (
        <>
            <Title className={'mb-5'}>{t("АПТЕКА")}</Title>
            <Content classNames={'bg-white rounded-2xl !min-h-[calc(100vh-300px)]'}>
                <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-12">
                        {(isLoadingConfirm || isLoadingAuthorization || isLoadingVerification) && <OverlayLoader/>}
                        <Form classNames={'grid grid-cols-12 gap-x-6 mb-5 items-start '}
                              formRequest={searchByDispensable}>
                            <Field classNames={'col-span-4'} placeholder={t('Номер рецепта')} type={FIELDS.input}
                                   name={'rx_code'}/>
                            <Button loading={isLoadingSearch} type={'submit'}
                                className={'col-span-2  !bg-[#006D85] !text-white mt-1.5 text-lg py-2.5'}>{t("Search")}</Button>

                        </Form>
                        <div
                            className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                            <Form classNames={''}
                                  formRequest={(values) => confirm(values, get(head(data), 'group_id', null))}
                                  footer={!isEmpty(data) &&
                                  <div className={'col-span-12 '}>
                                      <div className="flex justify-end">
                                          <button type={'submit'}
                                                  className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                              {t('Утвердить')}
                                          </button>
                                      </div>
                                  </div>}
                            >
                                {!isEmpty(data) ? <>
                                    <table className={'border  border-collapse text-center w-full'}>
                                        <thead>
                                        <th>{t("Номер рецепта")}</th>
                                        <th className={'p-3 border'}>{t("Наименование лекарства")}</th>
                                        <th className={'p-3 border'}>{t("Статус рецепта")}</th>
                                        <th className={'p-3 border'}>{t("Количество выписанного ЛС")}</th>
                                        <th className={'p-3 border'}>{t("Срок действия")}</th>
                                        </thead>
                                        <tbody>
                                        {
                                            data.map((_request, index) => <>
                                                    <tr key={get(_request, 'id')}
                                                        className={clsx({'bg-red-300': !isEqual(get(_request, 'status.code'), 'active')})}>
                                                        <td className={'p-3 border'}>{get(_request, 'rx_code')}</td>
                                                        <td className={'p-3 border'}>{get(_request, 'medication.vproduct.display')}</td>
                                                        <td className={'p-3 border'}>{get(_request, 'status.display')}</td>
                                                        <td className={'p-3 border'}>{get(_request, 'instructions', []).reduce((acc, obj) => acc + get(obj, 'quantity'), 0)}</td>
                                                        <td className={'p-3 border'}>{dayjs(get(_request, 'valid_until')).format("DD.MM.YYYY")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className={'grid grid-cols-12 gap-x-10 mt-3 mb-6 px-6'}>
                                                                <Field searchKeyName={'q'} dataKey={'payload.data.data'}
                                                                       type={FIELDS.asyncSelect}
                                                                       params={{required: true}}
                                                                       url={`${URLS.medicationBrands}?vproduct_id=${get(_request,'medication.vproduct.id')}`}
                                                                       keyId={KEYS.medicationBrands}
                                                                       classNames={'col-span-4'}
                                                                       name={`medication_dispenses[${index}].brand`}
                                                                       label={t('Торговое название')}
                                                                       property={{
                                                                           onChange: (val) => {
                                                                               let tempBrand = brand;
                                                                               tempBrand[index] = val;
                                                                               setBrand([...tempBrand])
                                                                           }
                                                                       }}
                                                                />
                                                                <Field
                                                                    defaultValue={get(_request, 'instructions', []).reduce((acc, obj) => acc + get(obj, 'quantity'), 0)}
                                                                    property={{
                                                                        type: 'number',
                                                                        max: {
                                                                            value: parseInt(get(_request, 'instructions', []).reduce((acc, obj) => acc + get(obj, 'quantity'), 0)),
                                                                            message: t('Количество должна быть не более ') + get(_request, 'instructions', []).reduce((acc, obj) => acc + get(obj, 'quantity'), 0)
                                                                        },
                                                                        onChange: (val) => {
                                                                            let tempQty = qty;
                                                                            tempQty[index] = val;
                                                                            setQty([...tempQty])
                                                                        }
                                                                    }} params={{required: true, valueAsNumber: true}}
                                                                    classNames={'col-span-4'} type={FIELDS.input}
                                                                    name={`medication_dispenses[${index}].order_details.qty`}
                                                                    label={t('Количество к отпуску')}/>
                                                                <Field params={{
                                                                    required: true,
                                                                    valueAsNumber: true,
                                                                    max: {
                                                                        value: get(brand, `[${index}].vproduct.approved_price`),
                                                                        message: t('Цена должна быть не более ') + get(brand, `[${index}].vproduct.approved_price`)
                                                                    }
                                                                }} property={{
                                                                    type: 'number',
                                                                    onChange: (val) => {
                                                                        if (!isNil(val)) {
                                                                            let tempPrice = price;
                                                                            tempPrice[index] = val;
                                                                            setPrice([...tempPrice])
                                                                        }
                                                                    }
                                                                }}
                                                                       type={FIELDS.input} classNames={'col-span-4'}
                                                                       name={`medication_dispenses[${index}].order_details.price_per_unit`}
                                                                       label={t('Стоимость одной единицы')}/>
                                                                <Field value={qty[index] * price[index]}
                                                                       property={{type: 'number', disabled: true}}
                                                                       type={FIELDS.input}
                                                                       classNames={'col-span-4'} name={'order_details.sum'}
                                                                       label={t('Обшая сумма')}/>
                                                                <Field value={get(_request, 'id')}
                                                                       property={{type: 'hidden'}} type={FIELDS.input}
                                                                       classNames={'col-span-4'}
                                                                       name={`medication_dispenses[${index}].medication_request.id`}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }

                                        </tbody>
                                    </table>
                                </> : <NoData/>}
                            </Form>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default ViewContainer;
