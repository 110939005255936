import React from 'react';
import {useParams} from "react-router-dom"
import Patient066Container from "../containers/Patient066CreateContainer";

const Patient066CreatePage = () => {
    const {id} = useParams()
    return (
        <>
            <Patient066Container id={id}/>
        </>
    );
};

export default Patient066CreatePage;
