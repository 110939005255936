import React, {useState} from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {useTranslation} from "react-i18next";
import {dropRight, get, isEqual, uniq} from "lodash"
import {useFieldArray, useFormContext} from "react-hook-form";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {Panel, Stack} from "rsuite";
import Title from "../title";

const Index = ({encounter_id}) => {
    const [selected, setSelected] = useState([])
    const {t} = useTranslation();
    const [answers, setAnswers] = useState([])
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'observations',
        keyName: 'uuid'
    });
    return (
        <>
            <Panel style={{overflow: 'unset'}} header={
                <Stack justifyContent="space-between">
                    <Title sm>{t("Blood tests")}</Title>
                    <Button htmlType={'button'} onClick={() => append({category: {id: 4},encounter:{id:encounter_id}})}
                            classNames={'p-1.5'}><Plus size={20}/></Button>
                </Stack>
            } bordered className={'col-span-12'} shaded>
            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12 mb-3">
                    <div className={'grid grid-cols-12 gap-x-6 items-start'}>
                        <Field
                            type={FIELDS.asyncSelect}
                               key={KEYS.concepts}
                               property={{
                                   optionLabel: 'display',
                                   optionValue: 'id',
                                   onChange: (val) => {
                                       setAnswers(get(val, 'answers', []))
                                       if (val) {
                                           setSelected(prev => uniq([...prev, get(val,'id')]))
                                       }
                                   },
                                   disabledOptions:selected
                               }}
                               dataKey={'payload.data'}
                               url={`${URLS.concepts}?class_code=test`}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`observations[${index}].concept`}
                               label={t('Analyze type')}
                               searchKeyName={null}
                        />
                        <Field type={FIELDS.select}
                               classNames={'col-span-4'} name={`observations[${index}].value_code`}
                               label={t('Analyze result')}
                               options={answers}
                        />
                        <Field type={FIELDS.datepicker}
                               params={{required: true}}
                               classNames={'col-span-3'} name={`observations[${index}].obs_date`}
                               label={t('Date')}
                        />
                      <div className="col-span-1 flex justify-end mt-6">
                            <Button htmlType={'button'} onClick={() => {
                                remove(index)
                                setSelected(prev=>dropRight(prev))
                            }}
                                    classNames={'p-1.5 border-red-400'}><Minus size={20} color={'red'}/></Button>
                        </div>
                    </div>
                </div>)
            }
            </Panel>
        </>
    );
};

export default Index;
