import React from 'react';
import Dropdown from "../dropdown";
import bellIcon from "../../assets/icons/bell.svg";
import {get} from "lodash";
import config from "../../config";
import {useGetAllQuery, usePostQuery, usePutQuery} from "../../hooks/api";
import {KEYS} from "../../constants/keys";
import {URLS} from "../../constants/urls";
import {X} from "react-feather";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    const {data: notifications} = useGetAllQuery({
        key: KEYS.notifications,
        url: URLS.notifications,
        refetchInterval: 5000,
        hideErrorMsg: true,
        params:{
            params:{
                sort:'-updated_at',
                per_page:100
            }
        }
    })
    const {
        mutate: ackNotificationRequest,
    } = usePutQuery({listKeyId: KEYS.notifications,hideSuccessToast:true})
    const {
        mutate: readAllNotificationRequest,
    } = usePostQuery({listKeyId: KEYS.notifications,hideSuccessToast:true})
    const {
        mutate: ackAllNotificationRequest,
    } = usePostQuery({listKeyId: KEYS.notifications,hideSuccessToast:true})

    const ackRequest = (item) => {
        ackNotificationRequest({
            url:`${URLS.notifications}/${get(item, 'id')}/acked`
        })
    }
    return (
        <>
            <div className={'cursor-pointer mr-8'}>
                <Dropdown callback={()=>readAllNotificationRequest({url:URLS.notificationsReadAll})} btn={<div className="flex items-center relative z-50">
                    <img src={bellIcon} alt=""/>
                    {get(notifications, 'data.payload.unread_count', 0) > 0 && <span
                        className={'absolute inline-flex justify-center items-center bg-red-400 w-[18px] h-[18px]  rounded-full text-[8px] text-white  overflow-hidden -right-1/2 -top-1/3'}>{get(notifications, 'data.payload.unread_count', 0)}</span>}
                </div>}>
                    <ul className={'w-[250px] bg-white absolute  rounded-b-xl p-2.5 z-50 max-h-[50vh] overflow-y-auto overflow-x-hidden right-1/2 translate-x-1/2 shadow'}>
                        <li onClick={()=>ackAllNotificationRequest({url:URLS.notificationsAckAll})} className={'p-1.5 font-semibold text-sm  text-center text-[#3F6A83]'}>{t("Clear all")}</li>
                        {
                            get(notifications, 'data.payload.data', []).map((item, i) => <li
                                className={' p-1.5 font-semibold text-sm  flex items-start justify-around'}>
                                <a rel="noopener noreferrer" download className={'text-blue-400 '}
                                   href={`${config.FILE_URL}${get(item, 'data.download_link')}`} onClick={()=>{
                                       ackNotificationRequest({
                                           url:`${URLS.notifications}/${get(item, 'id')}/acked`
                                       })
                                }}>{get(item, 'title')}</a>
                                <X  onClick={()=>ackRequest(item)} className={'flex-none cursor-pointer'} size={18} />
                            </li>)
                        }

                    </ul>
                </Dropdown>

            </div>
        </>
    );
};

export default Index;
